@import "../var";

.wrapper-offcanvas {
  //height: 100%;
  padding: 0;

  .offcanvas-header {
    margin: 0;
    padding: 0;

    .offcanvas-close {
      position: absolute;
      top: 0;
      right: 8px;
      z-index: 5;

      .btn {
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
      }
    }
  }

  .offcanvas-body {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: scroll;
  }

  //a.btn {
  //  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  //}

  /* SIZE MOBILE */
  &.s-mobile {
    width: $width_aside;
  }


}