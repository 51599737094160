/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "bootstrap/dist/css/bootstrap.css";
// => Material
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "highlight.js/styles/googlecode.css";
@import "perfect-scrollbar/css/perfect-scrollbar.css";

// VAR
@import "./assets/sass/var";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/_metronic/style";

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}
.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  thead{
    letter-spacing: 0.1rem;
  }

  .table.table-head-custom thead th.sortable-active {
    //color: $primary !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

// TOASTR
.toast-top-full-width{
  width: 100% !important;
}
