.card-header {
  padding: 0 1rem 0 1.5rem;
  min-height: 65px;
}

.bg-hover-gray:hover {
  background-color: #f3f3f3;
}

table {
  overflow: visible;

  tr {
    z-index: 3;
    position: relative;
  }
}