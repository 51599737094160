#app-breadcrumb {
  margin: 8px 0;
  padding: 0 25px;
  width: 100%;

  ul {
    padding: 0;
    margin: 0;
    font-weight: 100;

    li {
      display: inline-block;

      a {
        color: #888;
        font-size: 13px;
        text-shadow: none;

        &:hover {
          text-decoration: underline !important;
        }
      }

      i {
        color: #aaa;
        font-size: 14px;
        line-height: 14px;
        text-shadow: none;
        padding: 0 6px;

        &:first-child {
          padding: 0px 5px 0px 0px;
        }
      }
    }
  }
}
