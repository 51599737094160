@import "../var";

.wrapper-modal {
  height: 100%;
  padding: 0;
  //overflow: scroll;

  .modal-header {
    height: 60px;
    margin: 0;
    padding: 0 10px;

    .btn {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 7px 12px;

      i {
        font-size: 14px;
      }
    }
  }

  .modal-body {
    min-height: 200px;
    max-height: calc(100vh - 180px);
    overflow: scroll;
    margin: 0;
    padding: 0;
  }

  .modal-footer {
    height: 60px;
    margin: 0;
    padding: 0 10px;
  }
}

ngb-modal-window {
  background: rgb(0 0 0 / 0.5);
}