.accordion-item {
  &.grid-border {
    margin: 0;

    &.deepth-0 {
      border: 3px solid #dadce3;
      border-bottom: none;
      &:last-child {
        border: 3px solid #dadce3;
      }
    }
  }
}