.card {
  position: relative;
  z-index: 2;

  .card-header {
    z-index: 5;
  }
  .card-body {}
  .card-menu {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  }
}

.card-user {
  min-height: 110px;

  .datas {
    min-height: 110px;

    .symbol {
      align-items: center;
      justify-content: center;
      display: flex;
      height: 100%;
    }
  }

  action-toolbar {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.manager-card-list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 5px;
      padding: 8px 5px;
      cursor: pointer;
      display: flex;
      align-content: center;
      justify-content: space-between;
      border-radius: 8px;

      &.active {
        background: #ececf4;
        color: $blue;
      }

      &:hover {
        background: #ececf4;
      }
    }
  }

  i {
    color: inherit;
  }
}
