.step {
  width: 100%;

  .progressbar {
    counter-reset: step;

    li {
      list-style-type: none;
      width: 25%;
      float: left;
      font-size: 12px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #7d7d7d;

      span{
        border-radius: 5px;
        text-transform: capitalize;
        top: 15%;
      }

      &.active {
        span{
          background-color: #5CB85C;
          color: white;
        }
        .circle{
          color: #FFFFFF;
          background-color: #5CB85C;
          border-color: #5CB85C;
        }

        &:after {
          background-color: green;
          border-color: green;
        }
      }

      &.done {
        span{
          background-color: green;
          color: white;
        }

        .circle, &:after {
          color: #FFFFFF;
          background-color: green;
          border-color: green;
        }
      }

      &.unable {
        span{
          background-color: lightgray;
          color: black;
        }

        .circle, &:after {
          color: #FFFFFF;
          background-color: lightgray;
          border-color: lightgray;
        }
      }

      .circle {
        width: 15px;
        height: 15px;
        line-height: 30px;
        border: 2px solid #7d7d7d;
        display: block;
        text-align: center;
        margin: 10px auto;
        border-radius: 50%;
        background-color: white;
      }

      &:after {
        width: 100%;
        height: 6px;
        content: '';
        position: absolute;
        border: 1px solid #7d7d7d;
        //background-color: #7d7d7d;
        top: 32px;
        left: -50%;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
    }
  }
}
