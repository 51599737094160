pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em
}
code.hljs {
  padding: 3px 5px
}
/*

Google Code style (c) Aahan Krish <geekpanth3r@gmail.com>

*/
.hljs {
  background: white;
  color: black
}
.hljs-comment,
.hljs-quote {
  color: #800
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-section,
.hljs-title,
.hljs-name {
  color: #008
}
.hljs-variable,
.hljs-template-variable {
  color: #660
}
.hljs-string,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-regexp {
  color: #080
}
.hljs-literal,
.hljs-symbol,
.hljs-bullet,
.hljs-meta,
.hljs-number,
.hljs-link {
  color: #066
}
.hljs-title,
.hljs-doctag,
.hljs-type,
.hljs-attr,
.hljs-built_in,
.hljs-params {
  color: #606
}
.hljs-attribute,
.hljs-subst {
  color: #000
}
.hljs-formula {
  background-color: #eee;
  font-style: italic
}
.hljs-selector-id,
.hljs-selector-class {
  color: #9B703F
}
.hljs-addition {
  background-color: #baeeba
}
.hljs-deletion {
  background-color: #ffc8bd
}
.hljs-doctag,
.hljs-strong {
  font-weight: bold
}
.hljs-emphasis {
  font-style: italic
}