.timeline {
  .timeline-label {
    &::before {
      left: 51px;
    }

    &.large::before {
      left: 101px;
    }

    .timeline-item {
      margin: 0;

      .timeline-label {
        width: 50px;

        &.large {
          width: 100px;
        }
      }
    }
  }
}