@import "./_var";

.no-pointer {
  cursor: default;
  min-height: 0 !important;

  &:hover {
    transition: none !important;
    color: initial !important;

    i, .menu-title {
      color: initial !important;
    }
  }
}

/* WIDTH % */
@for $i from 1 through 12 {
  .w-#{$i} {
    width: calc((100% / 12) * $i) !important;
    --bs-modal-width: (100% / 12) * $i !important;
  }
}
/* WIDTH STATIC */
@for $i from 1 through 12 {
  .ws-#{$i} {
    width: $i * 100px;
    --bs-modal-width: $i * 100px;
  }
}
/* WIDTH ASIDE */
.wa {
  width: calc(100% - $width_aside) !important;
  --bs-modal-width: calc(100% - $width_aside) !important;
}


