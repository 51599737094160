@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/utilities";
@import '../../../node_modules/quill/dist/quill.snow.css';
@import '../../../node_modules/quill/dist/quill.bubble.css';

@import "./animations";
@import "./class";
@import "./color";
@import "./var";

@import "./layout/layout";
@import "./component/component";


body {
  //font-family: "Roboto Condensed";
  background: #EEF0F8;
}

h3 {
  margin: 0;
  padding: 0;
}

a {
  color: $color-main;
  &:hover {
    color: $color-secondary;
  }
}

pre {
  margin: 0 !important;
  padding: 0 !important;
  font-style: italic;
  color: #309eff;
}

.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
.min-height-content {
  min-height: calc(100vh - 66px - 28px);
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.flex-8 {
  flex: 8;
}
.flex-9 {
  flex: 9;
}
.flex-10 {
  flex: 10;
}
.flex-11 {
  flex: 11;
}
.flex-12 {
  flex: 12;
}

.mt--1 {
  margin-top: -1px;
}
.mt--2 {
  margin-top: -2px;
}
.mt--3 {
  margin-top: -3px;
}
.mt--4 {
  margin-top: -4px;
}
.mt--5 {
  margin-top: -5px;
}

.table-vertical-center {
  vertical-align: middle !important;
}

.text-success, .text-success > i {
  color: #1BC5BD;
}
.text-light-success, .text-light-success > i {
  color: #C9F7F5;
}
.text-danger, .text-danger > i {
  color: #F64E60;
}
.text-light-danger, .text-light-danger > i {
  color: #F64E60;
}

button:disabled {
  cursor: not-allowed !important;
}

.inline {
  display: inline !important;
}

/* LIST */
.list-icon {
  font-size: 30px;
  padding-right: 10px;
}
.list-subtitle {
  font-weight: 400;
  padding-top: 2px;
}
