.pac-container {
  z-index: 1060;
  width: 26.7em !important;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12);
  background: #fff;
  margin-left: -0.69% !important;
  margin-top: 11px;
  border-top: 0 !important;

  .pac-item{
    cursor: pointer !important;
    line-height: 3em !important;
    min-height: 3em;
    padding: 0 16px !important;
    text-decoration: none;
    color: rgba(0,0,0,0.87) !important;
    font-family: 'Poppins';
    border: 0;
  }
}
.pac-logo:after {
  content: none;
}
.cdk-overlay-container {
  z-index: 1051;
}

.pac-line1 {
  .pac-line1-icon {

  }
  .pac-line1-main {

  }
}
.pac-line2 {
  .pac-line2-main {

  }
  .pac-line2-secondary {

  }
}