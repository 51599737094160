#footer {
  position: inherit;
  height: auto;
  box-shadow: none;
  padding: 5px 0;
  margin: 0 0 -19px 0; // Due to Offcanvas
  font-size: 12px;
  color: #a1b2cf;

  a {
    color: #a1b2cf;
    &:hover {
      color: #a1b2cf;
    }
  }
}
