$dropdown-top: 55px !important;
$dropdown-left: -200px !important;
$dropdown-left-mobile: -300px !important;

// DROPDOWN
.menu-sub-dropdown {
  top: $dropdown-top;
  left: $dropdown-left;

  // IN ANIMATIONS
  &.dropdown-menu-anim-in {
    animation: animation-dropdown-menu-fade-in .3s ease 1, animation-dropdown-menu-move-up .3s ease-out 1 !important;
  }
  &.dropdown-menu-scale-down-anim-in {
    animation: growDown 300ms ease-in-out forwards !important;
  }
  &.dropdown-menu-scale-anim-in {
    animation: growOut 300ms ease-in-out forwards !important;
    transform-origin: top center !important;
  }
  &.dropdown-menu-rotate-anim-in {
    animation: rotateMenu 400ms ease-in-out forwards !important;
    transform-origin: top center !important;
  }
  &.dropdown-menu-translate-anim-in {
    animation: downOut 300ms ease-in-out forwards !important;
    transform-origin: center center !important;
  }

  // OUT ANIMATIONS
  &.dropdown-menu-anim-out {
    animation: animation-dropdown-menu-fade-out 300ms ease-out forwards !important;
  }
  &.dropdown-menu-scale-up-anim-out {
    animation: growUp 300ms ease-out forwards !important;
  }
  &.dropdown-menu-rotate-anim-out {
    animation: growUp 300ms ease-out forwards !important;
    transform-origin: top center !important
  }
}

// Tablet & mobile modes
/*@media (max-width: 992px){
  // DROPDOWN
  .menu-sub-dropdown {
    &.dropdown-menu-anim-up, &.dropdown-menu-scale-down-anim,
    &.dropdown-menu-scale-anim, &.dropdown-menu-rotate-anim, &.dropdown-menu-translate-anim{
      left: $dropdown-left-mobile;
    }
  }
}*/

// Animations
@keyframes animation-dropdown-menu-fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@keyframes animation-dropdown-menu-fade-out {
  from   { opacity: 1;}
  to { opacity: 0; visibility: hidden;}
}
@keyframes animation-dropdown-menu-move-down {
  from   { margin-top: 10px }
  to { margin-top: 0px }
}
@keyframes animation-dropdown-menu-move-up {
  from   { margin-top: 10px }
  to { margin-top: 0px }
}
@keyframes growDown {
  0% {
    transform: scaleY(0)
  }
  80% {
    transform: scaleY(1.1)
  }
  100% {
    transform: scaleY(1)
  }
}
@keyframes growUp {
  0% {
    transform: scaleY(1)
  }
  80% {
    transform: scaleY(1.1)
  }
  100% {
    transform: scaleY(0)
  }
}
@keyframes growOut {
  0% {
    transform: scale(0)
  }
  80% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}
@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg)
  }
  70% {
    transform: rotateX(20deg)
  }
  100% {
    transform: rotateX(0deg)
  }
}
@keyframes downOut {
  0% {
    transform: translateZ(200px) transLateY(40px)
  }
  80% {
    transform: translateZ(-10px) transLateY(0px)
  }
  100% {
    transform: translateZ(0px) transLateY(0px)
  }
}
