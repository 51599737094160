/*body, html{
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}*/
.app-context-menu {
  position: fixed;
  background-color: #F2F2F2;
  z-index: 101;
  box-shadow: 1px 1px 12px grey;
  padding: 0;
  border-radius: 6px;
}

.app-context-menu ul{
  list-style-type: none;
  padding: 0 !important;
  margin: 0;
}

.app-context-menu .cursor-pointer {
  padding: 8px 10px;
  font-size: 12px;
  color: #000000;
}

.app-context-menu .cursor-pointer:hover {
  background-color: #91C9F7;

  &:first-child {
    border-radius: 6px 6px 0 0;
  }
  &:last-child {
    border-radius: 0 0 6px 6px;
  }
  &:only-child {
    border-radius: 6px;
  }
}

.app-context-menu .cursor-pointer i{
  display: inline-block;
  margin-right: 5px;
  font-size: 1.1rem;
  color: #000000;
}
