/* TOPBAR */
.btn-topbar {
  width: auto;
  height: 44px;
}
.btn-topbar.btn-icon {
  width: 44px;
  height: 44px;
}

/* BTN */
.btn {
  &.btn-icon {
    i {
      font-size: 16px;
    }
  }
}

/* CLOSE */
.btn-close {
  background : none;
}
