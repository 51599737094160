.bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-primary;
  border-radius: 50%;
}

.menu-a2 .bubble {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 17px;
  width: 17px;
  font-size: 10px;
  font-weight: 500;
}

.menu-c2 .bubble {
  margin: 0 0 0 6px;
  padding: 1px 0 0 1px;
  height: 20px;
  width: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
}