.text-color-lighten-5, .text-color-lighten-5 > i {
        color :  #d0d6e2;
}
.label-color-lighten-5-white {
        background-color :  #d0d6e2;
        color : #fff;
}
.label-color-lighten-5 {
        background-color :  #d0d6e2;
        color : #000;
}
.bg-color-lighten-5 {
        background-color :  #d0d6e2;
}
.text-color-lighten-4, .text-color-lighten-4 > i {
        color :  #b1bace;
}
.label-color-lighten-4-white {
        background-color :  #b1bace;
        color : #fff;
}
.label-color-lighten-4 {
        background-color :  #b1bace;
        color : #000;
}
.bg-color-lighten-4 {
        background-color :  #b1bace;
}
.text-color-lighten-3, .text-color-lighten-3 > i {
        color :  #929fba;
}
.label-color-lighten-3-white {
        background-color :  #929fba;
        color : #fff;
}
.label-color-lighten-3 {
        background-color :  #929fba;
        color : #000;
}
.bg-color-lighten-3 {
        background-color :  #929fba;
}
.text-color-lighten-2, .text-color-lighten-2 > i {
        color :  #7283a7;
}
.label-color-lighten-2-white {
        background-color :  #7283a7;
        color : #fff;
}
.label-color-lighten-2 {
        background-color :  #7283a7;
        color : #000;
}
.bg-color-lighten-2 {
        background-color :  #7283a7;
}
.text-color-lighten-1, .text-color-lighten-1 > i {
        color :  #59698d;
}
.label-color-lighten-1-white {
        background-color :  #59698d;
        color : #fff;
}
.label-color-lighten-1 {
        background-color :  #59698d;
        color : #000;
}
.bg-color-lighten-1 {
        background-color :  #59698d;
}
.text-color-base, .text-color-base > i {
        color :       #45526e;
}
.label-color-base-white {
        background-color :       #45526e;
        color : #fff;
}
.label-color-base {
        background-color :       #45526e;
        color : #000;
}
.bg-color-base {
        background-color :       #45526e;
}
.text-color-darken-1, .text-color-darken-1 > i {
        color :   #3b465e;
}
.label-color-darken-1-white {
        background-color :   #3b465e;
        color : #fff;
}
.label-color-darken-1 {
        background-color :   #3b465e;
        color : #000;
}
.bg-color-darken-1 {
        background-color :   #3b465e;
}
.text-color-darken-2, .text-color-darken-2 > i {
        color :   #2e3951;
}
.label-color-darken-2-white {
        background-color :   #2e3951;
        color : #fff;
}
.label-color-darken-2 {
        background-color :   #2e3951;
        color : #000;
}
.bg-color-darken-2 {
        background-color :   #2e3951;
}
.text-color-darken-3, .text-color-darken-3 > i {
        color :   #1c2a48;
}
.label-color-darken-3-white {
        background-color :   #1c2a48;
        color : #fff;
}
.label-color-darken-3 {
        background-color :   #1c2a48;
        color : #000;
}
.bg-color-darken-3 {
        background-color :   #1c2a48;
}
.text-color-darken-4, .text-color-darken-4 > i {
        color :   #1c2331;
}
.label-color-darken-4-white {
        background-color :   #1c2331;
        color : #fff;
}
.label-color-darken-4 {
        background-color :   #1c2331;
        color : #000;
}
.bg-color-darken-4 {
        background-color :   #1c2331;
}
.text-red-lighten-5, .text-red-lighten-5 > i {
        color :  #ffebee;
}
.label-red-lighten-5-white {
        background-color :  #ffebee;
        color : #fff;
}
.label-red-lighten-5 {
        background-color :  #ffebee;
        color : #000;
}
.bg-red-lighten-5 {
        background-color :  #ffebee;
}
.text-red-lighten-4, .text-red-lighten-4 > i {
        color :  #ffcdd2;
}
.label-red-lighten-4-white {
        background-color :  #ffcdd2;
        color : #fff;
}
.label-red-lighten-4 {
        background-color :  #ffcdd2;
        color : #000;
}
.bg-red-lighten-4 {
        background-color :  #ffcdd2;
}
.text-red-lighten-3, .text-red-lighten-3 > i {
        color :  #ef9a9a;
}
.label-red-lighten-3-white {
        background-color :  #ef9a9a;
        color : #fff;
}
.label-red-lighten-3 {
        background-color :  #ef9a9a;
        color : #000;
}
.bg-red-lighten-3 {
        background-color :  #ef9a9a;
}
.text-red-lighten-2, .text-red-lighten-2 > i {
        color :  #e57373;
}
.label-red-lighten-2-white {
        background-color :  #e57373;
        color : #fff;
}
.label-red-lighten-2 {
        background-color :  #e57373;
        color : #000;
}
.bg-red-lighten-2 {
        background-color :  #e57373;
}
.text-red-lighten-1, .text-red-lighten-1 > i {
        color :  #ef5350;
}
.label-red-lighten-1-white {
        background-color :  #ef5350;
        color : #fff;
}
.label-red-lighten-1 {
        background-color :  #ef5350;
        color : #000;
}
.bg-red-lighten-1 {
        background-color :  #ef5350;
}
.text-red-base, .text-red-base > i {
        color :       #f44336;
}
.label-red-base-white {
        background-color :       #f44336;
        color : #fff;
}
.label-red-base {
        background-color :       #f44336;
        color : #000;
}
.bg-red-base {
        background-color :       #f44336;
}
.text-red-darken-1, .text-red-darken-1 > i {
        color :   #e53935;
}
.label-red-darken-1-white {
        background-color :   #e53935;
        color : #fff;
}
.label-red-darken-1 {
        background-color :   #e53935;
        color : #000;
}
.bg-red-darken-1 {
        background-color :   #e53935;
}
.text-red-darken-2, .text-red-darken-2 > i {
        color :   #d32f2f;
}
.label-red-darken-2-white {
        background-color :   #d32f2f;
        color : #fff;
}
.label-red-darken-2 {
        background-color :   #d32f2f;
        color : #000;
}
.bg-red-darken-2 {
        background-color :   #d32f2f;
}
.text-red-darken-3, .text-red-darken-3 > i {
        color :   #c62828;
}
.label-red-darken-3-white {
        background-color :   #c62828;
        color : #fff;
}
.label-red-darken-3 {
        background-color :   #c62828;
        color : #000;
}
.bg-red-darken-3 {
        background-color :   #c62828;
}
.text-red-darken-4, .text-red-darken-4 > i {
        color :   #b71c1c;
}
.label-red-darken-4-white {
        background-color :   #b71c1c;
        color : #fff;
}
.label-red-darken-4 {
        background-color :   #b71c1c;
        color : #000;
}
.bg-red-darken-4 {
        background-color :   #b71c1c;
}
.text-red-accent-1, .text-red-accent-1 > i {
        color :   #ff8a80;
}
.label-red-accent-1-white {
        background-color :   #ff8a80;
        color : #fff;
}
.label-red-accent-1 {
        background-color :   #ff8a80;
        color : #000;
}
.bg-red-accent-1 {
        background-color :   #ff8a80;
}
.text-red-accent-2, .text-red-accent-2 > i {
        color :   #ff5252;
}
.label-red-accent-2-white {
        background-color :   #ff5252;
        color : #fff;
}
.label-red-accent-2 {
        background-color :   #ff5252;
        color : #000;
}
.bg-red-accent-2 {
        background-color :   #ff5252;
}
.text-red-accent-3, .text-red-accent-3 > i {
        color :   #ff1744;
}
.label-red-accent-3-white {
        background-color :   #ff1744;
        color : #fff;
}
.label-red-accent-3 {
        background-color :   #ff1744;
        color : #000;
}
.bg-red-accent-3 {
        background-color :   #ff1744;
}
.text-red-accent-4, .text-red-accent-4 > i {
        color :   #d50000;
}
.label-red-accent-4-white {
        background-color :   #d50000;
        color : #fff;
}
.label-red-accent-4 {
        background-color :   #d50000;
        color : #000;
}
.bg-red-accent-4 {
        background-color :   #d50000;
}
.text-pink-lighten-5, .text-pink-lighten-5 > i {
        color :  #fce4ec;
}
.label-pink-lighten-5-white {
        background-color :  #fce4ec;
        color : #fff;
}
.label-pink-lighten-5 {
        background-color :  #fce4ec;
        color : #000;
}
.bg-pink-lighten-5 {
        background-color :  #fce4ec;
}
.text-pink-lighten-4, .text-pink-lighten-4 > i {
        color :  #f8bbd0;
}
.label-pink-lighten-4-white {
        background-color :  #f8bbd0;
        color : #fff;
}
.label-pink-lighten-4 {
        background-color :  #f8bbd0;
        color : #000;
}
.bg-pink-lighten-4 {
        background-color :  #f8bbd0;
}
.text-pink-lighten-3, .text-pink-lighten-3 > i {
        color :  #f48fb1;
}
.label-pink-lighten-3-white {
        background-color :  #f48fb1;
        color : #fff;
}
.label-pink-lighten-3 {
        background-color :  #f48fb1;
        color : #000;
}
.bg-pink-lighten-3 {
        background-color :  #f48fb1;
}
.text-pink-lighten-2, .text-pink-lighten-2 > i {
        color :  #f06292;
}
.label-pink-lighten-2-white {
        background-color :  #f06292;
        color : #fff;
}
.label-pink-lighten-2 {
        background-color :  #f06292;
        color : #000;
}
.bg-pink-lighten-2 {
        background-color :  #f06292;
}
.text-pink-lighten-1, .text-pink-lighten-1 > i {
        color :  #ec407a;
}
.label-pink-lighten-1-white {
        background-color :  #ec407a;
        color : #fff;
}
.label-pink-lighten-1 {
        background-color :  #ec407a;
        color : #000;
}
.bg-pink-lighten-1 {
        background-color :  #ec407a;
}
.text-pink-base, .text-pink-base > i {
        color :       #e91e63;
}
.label-pink-base-white {
        background-color :       #e91e63;
        color : #fff;
}
.label-pink-base {
        background-color :       #e91e63;
        color : #000;
}
.bg-pink-base {
        background-color :       #e91e63;
}
.text-pink-darken-1, .text-pink-darken-1 > i {
        color :   #d81b60;
}
.label-pink-darken-1-white {
        background-color :   #d81b60;
        color : #fff;
}
.label-pink-darken-1 {
        background-color :   #d81b60;
        color : #000;
}
.bg-pink-darken-1 {
        background-color :   #d81b60;
}
.text-pink-darken-2, .text-pink-darken-2 > i {
        color :   #c2185b;
}
.label-pink-darken-2-white {
        background-color :   #c2185b;
        color : #fff;
}
.label-pink-darken-2 {
        background-color :   #c2185b;
        color : #000;
}
.bg-pink-darken-2 {
        background-color :   #c2185b;
}
.text-pink-darken-3, .text-pink-darken-3 > i {
        color :   #ad1457;
}
.label-pink-darken-3-white {
        background-color :   #ad1457;
        color : #fff;
}
.label-pink-darken-3 {
        background-color :   #ad1457;
        color : #000;
}
.bg-pink-darken-3 {
        background-color :   #ad1457;
}
.text-pink-darken-4, .text-pink-darken-4 > i {
        color :   #880e4f;
}
.label-pink-darken-4-white {
        background-color :   #880e4f;
        color : #fff;
}
.label-pink-darken-4 {
        background-color :   #880e4f;
        color : #000;
}
.bg-pink-darken-4 {
        background-color :   #880e4f;
}
.text-pink-accent-1, .text-pink-accent-1 > i {
        color :   #ff80ab;
}
.label-pink-accent-1-white {
        background-color :   #ff80ab;
        color : #fff;
}
.label-pink-accent-1 {
        background-color :   #ff80ab;
        color : #000;
}
.bg-pink-accent-1 {
        background-color :   #ff80ab;
}
.text-pink-accent-2, .text-pink-accent-2 > i {
        color :   #ff4081;
}
.label-pink-accent-2-white {
        background-color :   #ff4081;
        color : #fff;
}
.label-pink-accent-2 {
        background-color :   #ff4081;
        color : #000;
}
.bg-pink-accent-2 {
        background-color :   #ff4081;
}
.text-pink-accent-3, .text-pink-accent-3 > i {
        color :   #f50057;
}
.label-pink-accent-3-white {
        background-color :   #f50057;
        color : #fff;
}
.label-pink-accent-3 {
        background-color :   #f50057;
        color : #000;
}
.bg-pink-accent-3 {
        background-color :   #f50057;
}
.text-pink-accent-4, .text-pink-accent-4 > i {
        color :   #c51162;
}
.label-pink-accent-4-white {
        background-color :   #c51162;
        color : #fff;
}
.label-pink-accent-4 {
        background-color :   #c51162;
        color : #000;
}
.bg-pink-accent-4 {
        background-color :   #c51162;
}
.text-purple-lighten-5, .text-purple-lighten-5 > i {
        color :  #f3e5f5;
}
.label-purple-lighten-5-white {
        background-color :  #f3e5f5;
        color : #fff;
}
.label-purple-lighten-5 {
        background-color :  #f3e5f5;
        color : #000;
}
.bg-purple-lighten-5 {
        background-color :  #f3e5f5;
}
.text-purple-lighten-4, .text-purple-lighten-4 > i {
        color :  #e1bee7;
}
.label-purple-lighten-4-white {
        background-color :  #e1bee7;
        color : #fff;
}
.label-purple-lighten-4 {
        background-color :  #e1bee7;
        color : #000;
}
.bg-purple-lighten-4 {
        background-color :  #e1bee7;
}
.text-purple-lighten-3, .text-purple-lighten-3 > i {
        color :  #ce93d8;
}
.label-purple-lighten-3-white {
        background-color :  #ce93d8;
        color : #fff;
}
.label-purple-lighten-3 {
        background-color :  #ce93d8;
        color : #000;
}
.bg-purple-lighten-3 {
        background-color :  #ce93d8;
}
.text-purple-lighten-2, .text-purple-lighten-2 > i {
        color :  #ba68c8;
}
.label-purple-lighten-2-white {
        background-color :  #ba68c8;
        color : #fff;
}
.label-purple-lighten-2 {
        background-color :  #ba68c8;
        color : #000;
}
.bg-purple-lighten-2 {
        background-color :  #ba68c8;
}
.text-purple-lighten-1, .text-purple-lighten-1 > i {
        color :  #ab47bc;
}
.label-purple-lighten-1-white {
        background-color :  #ab47bc;
        color : #fff;
}
.label-purple-lighten-1 {
        background-color :  #ab47bc;
        color : #000;
}
.bg-purple-lighten-1 {
        background-color :  #ab47bc;
}
.text-purple-base, .text-purple-base > i {
        color :       #9c27b0;
}
.label-purple-base-white {
        background-color :       #9c27b0;
        color : #fff;
}
.label-purple-base {
        background-color :       #9c27b0;
        color : #000;
}
.bg-purple-base {
        background-color :       #9c27b0;
}
.text-purple-darken-1, .text-purple-darken-1 > i {
        color :   #8e24aa;
}
.label-purple-darken-1-white {
        background-color :   #8e24aa;
        color : #fff;
}
.label-purple-darken-1 {
        background-color :   #8e24aa;
        color : #000;
}
.bg-purple-darken-1 {
        background-color :   #8e24aa;
}
.text-purple-darken-2, .text-purple-darken-2 > i {
        color :   #7b1fa2;
}
.label-purple-darken-2-white {
        background-color :   #7b1fa2;
        color : #fff;
}
.label-purple-darken-2 {
        background-color :   #7b1fa2;
        color : #000;
}
.bg-purple-darken-2 {
        background-color :   #7b1fa2;
}
.text-purple-darken-3, .text-purple-darken-3 > i {
        color :   #6a1b9a;
}
.label-purple-darken-3-white {
        background-color :   #6a1b9a;
        color : #fff;
}
.label-purple-darken-3 {
        background-color :   #6a1b9a;
        color : #000;
}
.bg-purple-darken-3 {
        background-color :   #6a1b9a;
}
.text-purple-darken-4, .text-purple-darken-4 > i {
        color :   #4a148c;
}
.label-purple-darken-4-white {
        background-color :   #4a148c;
        color : #fff;
}
.label-purple-darken-4 {
        background-color :   #4a148c;
        color : #000;
}
.bg-purple-darken-4 {
        background-color :   #4a148c;
}
.text-purple-accent-1, .text-purple-accent-1 > i {
        color :   #ea80fc;
}
.label-purple-accent-1-white {
        background-color :   #ea80fc;
        color : #fff;
}
.label-purple-accent-1 {
        background-color :   #ea80fc;
        color : #000;
}
.bg-purple-accent-1 {
        background-color :   #ea80fc;
}
.text-purple-accent-2, .text-purple-accent-2 > i {
        color :   #e040fb;
}
.label-purple-accent-2-white {
        background-color :   #e040fb;
        color : #fff;
}
.label-purple-accent-2 {
        background-color :   #e040fb;
        color : #000;
}
.bg-purple-accent-2 {
        background-color :   #e040fb;
}
.text-purple-accent-3, .text-purple-accent-3 > i {
        color :   #d500f9;
}
.label-purple-accent-3-white {
        background-color :   #d500f9;
        color : #fff;
}
.label-purple-accent-3 {
        background-color :   #d500f9;
        color : #000;
}
.bg-purple-accent-3 {
        background-color :   #d500f9;
}
.text-purple-accent-4, .text-purple-accent-4 > i {
        color :   #aa00ff;
}
.label-purple-accent-4-white {
        background-color :   #aa00ff;
        color : #fff;
}
.label-purple-accent-4 {
        background-color :   #aa00ff;
        color : #000;
}
.bg-purple-accent-4 {
        background-color :   #aa00ff;
}
.text-deep-purple-lighten-5, .text-deep-purple-lighten-5 > i {
        color :  #ede7f6;
}
.label-deep-purple-lighten-5-white {
        background-color :  #ede7f6;
        color : #fff;
}
.label-deep-purple-lighten-5 {
        background-color :  #ede7f6;
        color : #000;
}
.bg-deep-purple-lighten-5 {
        background-color :  #ede7f6;
}
.text-deep-purple-lighten-4, .text-deep-purple-lighten-4 > i {
        color :  #d1c4e9;
}
.label-deep-purple-lighten-4-white {
        background-color :  #d1c4e9;
        color : #fff;
}
.label-deep-purple-lighten-4 {
        background-color :  #d1c4e9;
        color : #000;
}
.bg-deep-purple-lighten-4 {
        background-color :  #d1c4e9;
}
.text-deep-purple-lighten-3, .text-deep-purple-lighten-3 > i {
        color :  #b39ddb;
}
.label-deep-purple-lighten-3-white {
        background-color :  #b39ddb;
        color : #fff;
}
.label-deep-purple-lighten-3 {
        background-color :  #b39ddb;
        color : #000;
}
.bg-deep-purple-lighten-3 {
        background-color :  #b39ddb;
}
.text-deep-purple-lighten-2, .text-deep-purple-lighten-2 > i {
        color :  #9575cd;
}
.label-deep-purple-lighten-2-white {
        background-color :  #9575cd;
        color : #fff;
}
.label-deep-purple-lighten-2 {
        background-color :  #9575cd;
        color : #000;
}
.bg-deep-purple-lighten-2 {
        background-color :  #9575cd;
}
.text-deep-purple-lighten-1, .text-deep-purple-lighten-1 > i {
        color :  #7e57c2;
}
.label-deep-purple-lighten-1-white {
        background-color :  #7e57c2;
        color : #fff;
}
.label-deep-purple-lighten-1 {
        background-color :  #7e57c2;
        color : #000;
}
.bg-deep-purple-lighten-1 {
        background-color :  #7e57c2;
}
.text-deep-purple-base, .text-deep-purple-base > i {
        color :       #673ab7;
}
.label-deep-purple-base-white {
        background-color :       #673ab7;
        color : #fff;
}
.label-deep-purple-base {
        background-color :       #673ab7;
        color : #000;
}
.bg-deep-purple-base {
        background-color :       #673ab7;
}
.text-deep-purple-darken-1, .text-deep-purple-darken-1 > i {
        color :   #5e35b1;
}
.label-deep-purple-darken-1-white {
        background-color :   #5e35b1;
        color : #fff;
}
.label-deep-purple-darken-1 {
        background-color :   #5e35b1;
        color : #000;
}
.bg-deep-purple-darken-1 {
        background-color :   #5e35b1;
}
.text-deep-purple-darken-2, .text-deep-purple-darken-2 > i {
        color :   #512da8;
}
.label-deep-purple-darken-2-white {
        background-color :   #512da8;
        color : #fff;
}
.label-deep-purple-darken-2 {
        background-color :   #512da8;
        color : #000;
}
.bg-deep-purple-darken-2 {
        background-color :   #512da8;
}
.text-deep-purple-darken-3, .text-deep-purple-darken-3 > i {
        color :   #4527a0;
}
.label-deep-purple-darken-3-white {
        background-color :   #4527a0;
        color : #fff;
}
.label-deep-purple-darken-3 {
        background-color :   #4527a0;
        color : #000;
}
.bg-deep-purple-darken-3 {
        background-color :   #4527a0;
}
.text-deep-purple-darken-4, .text-deep-purple-darken-4 > i {
        color :   #311b92;
}
.label-deep-purple-darken-4-white {
        background-color :   #311b92;
        color : #fff;
}
.label-deep-purple-darken-4 {
        background-color :   #311b92;
        color : #000;
}
.bg-deep-purple-darken-4 {
        background-color :   #311b92;
}
.text-deep-purple-accent-1, .text-deep-purple-accent-1 > i {
        color :   #b388ff;
}
.label-deep-purple-accent-1-white {
        background-color :   #b388ff;
        color : #fff;
}
.label-deep-purple-accent-1 {
        background-color :   #b388ff;
        color : #000;
}
.bg-deep-purple-accent-1 {
        background-color :   #b388ff;
}
.text-deep-purple-accent-2, .text-deep-purple-accent-2 > i {
        color :   #7c4dff;
}
.label-deep-purple-accent-2-white {
        background-color :   #7c4dff;
        color : #fff;
}
.label-deep-purple-accent-2 {
        background-color :   #7c4dff;
        color : #000;
}
.bg-deep-purple-accent-2 {
        background-color :   #7c4dff;
}
.text-deep-purple-accent-3, .text-deep-purple-accent-3 > i {
        color :   #651fff;
}
.label-deep-purple-accent-3-white {
        background-color :   #651fff;
        color : #fff;
}
.label-deep-purple-accent-3 {
        background-color :   #651fff;
        color : #000;
}
.bg-deep-purple-accent-3 {
        background-color :   #651fff;
}
.text-deep-purple-accent-4, .text-deep-purple-accent-4 > i {
        color :   #6200ea;
}
.label-deep-purple-accent-4-white {
        background-color :   #6200ea;
        color : #fff;
}
.label-deep-purple-accent-4 {
        background-color :   #6200ea;
        color : #000;
}
.bg-deep-purple-accent-4 {
        background-color :   #6200ea;
}
.text-indigo-lighten-5, .text-indigo-lighten-5 > i {
        color :  #e8eaf6;
}
.label-indigo-lighten-5-white {
        background-color :  #e8eaf6;
        color : #fff;
}
.label-indigo-lighten-5 {
        background-color :  #e8eaf6;
        color : #000;
}
.bg-indigo-lighten-5 {
        background-color :  #e8eaf6;
}
.text-indigo-lighten-4, .text-indigo-lighten-4 > i {
        color :  #c5cae9;
}
.label-indigo-lighten-4-white {
        background-color :  #c5cae9;
        color : #fff;
}
.label-indigo-lighten-4 {
        background-color :  #c5cae9;
        color : #000;
}
.bg-indigo-lighten-4 {
        background-color :  #c5cae9;
}
.text-indigo-lighten-3, .text-indigo-lighten-3 > i {
        color :  #9fa8da;
}
.label-indigo-lighten-3-white {
        background-color :  #9fa8da;
        color : #fff;
}
.label-indigo-lighten-3 {
        background-color :  #9fa8da;
        color : #000;
}
.bg-indigo-lighten-3 {
        background-color :  #9fa8da;
}
.text-indigo-lighten-2, .text-indigo-lighten-2 > i {
        color :  #7986cb;
}
.label-indigo-lighten-2-white {
        background-color :  #7986cb;
        color : #fff;
}
.label-indigo-lighten-2 {
        background-color :  #7986cb;
        color : #000;
}
.bg-indigo-lighten-2 {
        background-color :  #7986cb;
}
.text-indigo-lighten-1, .text-indigo-lighten-1 > i {
        color :  #5c6bc0;
}
.label-indigo-lighten-1-white {
        background-color :  #5c6bc0;
        color : #fff;
}
.label-indigo-lighten-1 {
        background-color :  #5c6bc0;
        color : #000;
}
.bg-indigo-lighten-1 {
        background-color :  #5c6bc0;
}
.text-indigo-base, .text-indigo-base > i {
        color :       #3f51b5;
}
.label-indigo-base-white {
        background-color :       #3f51b5;
        color : #fff;
}
.label-indigo-base {
        background-color :       #3f51b5;
        color : #000;
}
.bg-indigo-base {
        background-color :       #3f51b5;
}
.text-indigo-darken-1, .text-indigo-darken-1 > i {
        color :   #3949ab;
}
.label-indigo-darken-1-white {
        background-color :   #3949ab;
        color : #fff;
}
.label-indigo-darken-1 {
        background-color :   #3949ab;
        color : #000;
}
.bg-indigo-darken-1 {
        background-color :   #3949ab;
}
.text-indigo-darken-2, .text-indigo-darken-2 > i {
        color :   #303f9f;
}
.label-indigo-darken-2-white {
        background-color :   #303f9f;
        color : #fff;
}
.label-indigo-darken-2 {
        background-color :   #303f9f;
        color : #000;
}
.bg-indigo-darken-2 {
        background-color :   #303f9f;
}
.text-indigo-darken-3, .text-indigo-darken-3 > i {
        color :   #283593;
}
.label-indigo-darken-3-white {
        background-color :   #283593;
        color : #fff;
}
.label-indigo-darken-3 {
        background-color :   #283593;
        color : #000;
}
.bg-indigo-darken-3 {
        background-color :   #283593;
}
.text-indigo-darken-4, .text-indigo-darken-4 > i {
        color :   #1a237e;
}
.label-indigo-darken-4-white {
        background-color :   #1a237e;
        color : #fff;
}
.label-indigo-darken-4 {
        background-color :   #1a237e;
        color : #000;
}
.bg-indigo-darken-4 {
        background-color :   #1a237e;
}
.text-indigo-accent-1, .text-indigo-accent-1 > i {
        color :   #8c9eff;
}
.label-indigo-accent-1-white {
        background-color :   #8c9eff;
        color : #fff;
}
.label-indigo-accent-1 {
        background-color :   #8c9eff;
        color : #000;
}
.bg-indigo-accent-1 {
        background-color :   #8c9eff;
}
.text-indigo-accent-2, .text-indigo-accent-2 > i {
        color :   #536dfe;
}
.label-indigo-accent-2-white {
        background-color :   #536dfe;
        color : #fff;
}
.label-indigo-accent-2 {
        background-color :   #536dfe;
        color : #000;
}
.bg-indigo-accent-2 {
        background-color :   #536dfe;
}
.text-indigo-accent-3, .text-indigo-accent-3 > i {
        color :   #3d5afe;
}
.label-indigo-accent-3-white {
        background-color :   #3d5afe;
        color : #fff;
}
.label-indigo-accent-3 {
        background-color :   #3d5afe;
        color : #000;
}
.bg-indigo-accent-3 {
        background-color :   #3d5afe;
}
.text-indigo-accent-4, .text-indigo-accent-4 > i {
        color :   #304ffe;
}
.label-indigo-accent-4-white {
        background-color :   #304ffe;
        color : #fff;
}
.label-indigo-accent-4 {
        background-color :   #304ffe;
        color : #000;
}
.bg-indigo-accent-4 {
        background-color :   #304ffe;
}
.text-blue-lighten-5, .text-blue-lighten-5 > i {
        color :  #e3f2fd;
}
.label-blue-lighten-5-white {
        background-color :  #e3f2fd;
        color : #fff;
}
.label-blue-lighten-5 {
        background-color :  #e3f2fd;
        color : #000;
}
.bg-blue-lighten-5 {
        background-color :  #e3f2fd;
}
.text-blue-lighten-4, .text-blue-lighten-4 > i {
        color :  #bbdefb;
}
.label-blue-lighten-4-white {
        background-color :  #bbdefb;
        color : #fff;
}
.label-blue-lighten-4 {
        background-color :  #bbdefb;
        color : #000;
}
.bg-blue-lighten-4 {
        background-color :  #bbdefb;
}
.text-blue-lighten-3, .text-blue-lighten-3 > i {
        color :  #90caf9;
}
.label-blue-lighten-3-white {
        background-color :  #90caf9;
        color : #fff;
}
.label-blue-lighten-3 {
        background-color :  #90caf9;
        color : #000;
}
.bg-blue-lighten-3 {
        background-color :  #90caf9;
}
.text-blue-lighten-2, .text-blue-lighten-2 > i {
        color :  #64b5f6;
}
.label-blue-lighten-2-white {
        background-color :  #64b5f6;
        color : #fff;
}
.label-blue-lighten-2 {
        background-color :  #64b5f6;
        color : #000;
}
.bg-blue-lighten-2 {
        background-color :  #64b5f6;
}
.text-blue-lighten-1, .text-blue-lighten-1 > i {
        color :  #42a5f5;
}
.label-blue-lighten-1-white {
        background-color :  #42a5f5;
        color : #fff;
}
.label-blue-lighten-1 {
        background-color :  #42a5f5;
        color : #000;
}
.bg-blue-lighten-1 {
        background-color :  #42a5f5;
}
.text-blue-base, .text-blue-base > i {
        color :       #2196f3;
}
.label-blue-base-white {
        background-color :       #2196f3;
        color : #fff;
}
.label-blue-base {
        background-color :       #2196f3;
        color : #000;
}
.bg-blue-base {
        background-color :       #2196f3;
}
.text-blue-darken-1, .text-blue-darken-1 > i {
        color :   #1e88e5;
}
.label-blue-darken-1-white {
        background-color :   #1e88e5;
        color : #fff;
}
.label-blue-darken-1 {
        background-color :   #1e88e5;
        color : #000;
}
.bg-blue-darken-1 {
        background-color :   #1e88e5;
}
.text-blue-darken-2, .text-blue-darken-2 > i {
        color :   #1976d2;
}
.label-blue-darken-2-white {
        background-color :   #1976d2;
        color : #fff;
}
.label-blue-darken-2 {
        background-color :   #1976d2;
        color : #000;
}
.bg-blue-darken-2 {
        background-color :   #1976d2;
}
.text-blue-darken-3, .text-blue-darken-3 > i {
        color :   #1565c0;
}
.label-blue-darken-3-white {
        background-color :   #1565c0;
        color : #fff;
}
.label-blue-darken-3 {
        background-color :   #1565c0;
        color : #000;
}
.bg-blue-darken-3 {
        background-color :   #1565c0;
}
.text-blue-darken-4, .text-blue-darken-4 > i {
        color :   #0d47a1;
}
.label-blue-darken-4-white {
        background-color :   #0d47a1;
        color : #fff;
}
.label-blue-darken-4 {
        background-color :   #0d47a1;
        color : #000;
}
.bg-blue-darken-4 {
        background-color :   #0d47a1;
}
.text-blue-accent-1, .text-blue-accent-1 > i {
        color :   #82b1ff;
}
.label-blue-accent-1-white {
        background-color :   #82b1ff;
        color : #fff;
}
.label-blue-accent-1 {
        background-color :   #82b1ff;
        color : #000;
}
.bg-blue-accent-1 {
        background-color :   #82b1ff;
}
.text-blue-accent-2, .text-blue-accent-2 > i {
        color :   #448aff;
}
.label-blue-accent-2-white {
        background-color :   #448aff;
        color : #fff;
}
.label-blue-accent-2 {
        background-color :   #448aff;
        color : #000;
}
.bg-blue-accent-2 {
        background-color :   #448aff;
}
.text-blue-accent-3, .text-blue-accent-3 > i {
        color :   #2979ff;
}
.label-blue-accent-3-white {
        background-color :   #2979ff;
        color : #fff;
}
.label-blue-accent-3 {
        background-color :   #2979ff;
        color : #000;
}
.bg-blue-accent-3 {
        background-color :   #2979ff;
}
.text-blue-accent-4, .text-blue-accent-4 > i {
        color :   #2962ff;
}
.label-blue-accent-4-white {
        background-color :   #2962ff;
        color : #fff;
}
.label-blue-accent-4 {
        background-color :   #2962ff;
        color : #000;
}
.bg-blue-accent-4 {
        background-color :   #2962ff;
}
.text-light-blue-lighten-5, .text-light-blue-lighten-5 > i {
        color :  #e1f5fe;
}
.label-light-blue-lighten-5-white {
        background-color :  #e1f5fe;
        color : #fff;
}
.label-light-blue-lighten-5 {
        background-color :  #e1f5fe;
        color : #000;
}
.bg-light-blue-lighten-5 {
        background-color :  #e1f5fe;
}
.text-light-blue-lighten-4, .text-light-blue-lighten-4 > i {
        color :  #b3e5fc;
}
.label-light-blue-lighten-4-white {
        background-color :  #b3e5fc;
        color : #fff;
}
.label-light-blue-lighten-4 {
        background-color :  #b3e5fc;
        color : #000;
}
.bg-light-blue-lighten-4 {
        background-color :  #b3e5fc;
}
.text-light-blue-lighten-3, .text-light-blue-lighten-3 > i {
        color :  #81d4fa;
}
.label-light-blue-lighten-3-white {
        background-color :  #81d4fa;
        color : #fff;
}
.label-light-blue-lighten-3 {
        background-color :  #81d4fa;
        color : #000;
}
.bg-light-blue-lighten-3 {
        background-color :  #81d4fa;
}
.text-light-blue-lighten-2, .text-light-blue-lighten-2 > i {
        color :  #4fc3f7;
}
.label-light-blue-lighten-2-white {
        background-color :  #4fc3f7;
        color : #fff;
}
.label-light-blue-lighten-2 {
        background-color :  #4fc3f7;
        color : #000;
}
.bg-light-blue-lighten-2 {
        background-color :  #4fc3f7;
}
.text-light-blue-lighten-1, .text-light-blue-lighten-1 > i {
        color :  #29b6f6;
}
.label-light-blue-lighten-1-white {
        background-color :  #29b6f6;
        color : #fff;
}
.label-light-blue-lighten-1 {
        background-color :  #29b6f6;
        color : #000;
}
.bg-light-blue-lighten-1 {
        background-color :  #29b6f6;
}
.text-light-blue-base, .text-light-blue-base > i {
        color :       #03a9f4;
}
.label-light-blue-base-white {
        background-color :       #03a9f4;
        color : #fff;
}
.label-light-blue-base {
        background-color :       #03a9f4;
        color : #000;
}
.bg-light-blue-base {
        background-color :       #03a9f4;
}
.text-light-blue-darken-1, .text-light-blue-darken-1 > i {
        color :   #039be5;
}
.label-light-blue-darken-1-white {
        background-color :   #039be5;
        color : #fff;
}
.label-light-blue-darken-1 {
        background-color :   #039be5;
        color : #000;
}
.bg-light-blue-darken-1 {
        background-color :   #039be5;
}
.text-light-blue-darken-2, .text-light-blue-darken-2 > i {
        color :   #0288d1;
}
.label-light-blue-darken-2-white {
        background-color :   #0288d1;
        color : #fff;
}
.label-light-blue-darken-2 {
        background-color :   #0288d1;
        color : #000;
}
.bg-light-blue-darken-2 {
        background-color :   #0288d1;
}
.text-light-blue-darken-3, .text-light-blue-darken-3 > i {
        color :   #0277bd;
}
.label-light-blue-darken-3-white {
        background-color :   #0277bd;
        color : #fff;
}
.label-light-blue-darken-3 {
        background-color :   #0277bd;
        color : #000;
}
.bg-light-blue-darken-3 {
        background-color :   #0277bd;
}
.text-light-blue-darken-4, .text-light-blue-darken-4 > i {
        color :   #01579b;
}
.label-light-blue-darken-4-white {
        background-color :   #01579b;
        color : #fff;
}
.label-light-blue-darken-4 {
        background-color :   #01579b;
        color : #000;
}
.bg-light-blue-darken-4 {
        background-color :   #01579b;
}
.text-light-blue-accent-1, .text-light-blue-accent-1 > i {
        color :   #80d8ff;
}
.label-light-blue-accent-1-white {
        background-color :   #80d8ff;
        color : #fff;
}
.label-light-blue-accent-1 {
        background-color :   #80d8ff;
        color : #000;
}
.bg-light-blue-accent-1 {
        background-color :   #80d8ff;
}
.text-light-blue-accent-2, .text-light-blue-accent-2 > i {
        color :   #40c4ff;
}
.label-light-blue-accent-2-white {
        background-color :   #40c4ff;
        color : #fff;
}
.label-light-blue-accent-2 {
        background-color :   #40c4ff;
        color : #000;
}
.bg-light-blue-accent-2 {
        background-color :   #40c4ff;
}
.text-light-blue-accent-3, .text-light-blue-accent-3 > i {
        color :   #00b0ff;
}
.label-light-blue-accent-3-white {
        background-color :   #00b0ff;
        color : #fff;
}
.label-light-blue-accent-3 {
        background-color :   #00b0ff;
        color : #000;
}
.bg-light-blue-accent-3 {
        background-color :   #00b0ff;
}
.text-light-blue-accent-4, .text-light-blue-accent-4 > i {
        color :   #0091ea;
}
.label-light-blue-accent-4-white {
        background-color :   #0091ea;
        color : #fff;
}
.label-light-blue-accent-4 {
        background-color :   #0091ea;
        color : #000;
}
.bg-light-blue-accent-4 {
        background-color :   #0091ea;
}
.text-cyan-lighten-5, .text-cyan-lighten-5 > i {
        color :  #e0f7fa;
}
.label-cyan-lighten-5-white {
        background-color :  #e0f7fa;
        color : #fff;
}
.label-cyan-lighten-5 {
        background-color :  #e0f7fa;
        color : #000;
}
.bg-cyan-lighten-5 {
        background-color :  #e0f7fa;
}
.text-cyan-lighten-4, .text-cyan-lighten-4 > i {
        color :  #b2ebf2;
}
.label-cyan-lighten-4-white {
        background-color :  #b2ebf2;
        color : #fff;
}
.label-cyan-lighten-4 {
        background-color :  #b2ebf2;
        color : #000;
}
.bg-cyan-lighten-4 {
        background-color :  #b2ebf2;
}
.text-cyan-lighten-3, .text-cyan-lighten-3 > i {
        color :  #80deea;
}
.label-cyan-lighten-3-white {
        background-color :  #80deea;
        color : #fff;
}
.label-cyan-lighten-3 {
        background-color :  #80deea;
        color : #000;
}
.bg-cyan-lighten-3 {
        background-color :  #80deea;
}
.text-cyan-lighten-2, .text-cyan-lighten-2 > i {
        color :  #4dd0e1;
}
.label-cyan-lighten-2-white {
        background-color :  #4dd0e1;
        color : #fff;
}
.label-cyan-lighten-2 {
        background-color :  #4dd0e1;
        color : #000;
}
.bg-cyan-lighten-2 {
        background-color :  #4dd0e1;
}
.text-cyan-lighten-1, .text-cyan-lighten-1 > i {
        color :  #26c6da;
}
.label-cyan-lighten-1-white {
        background-color :  #26c6da;
        color : #fff;
}
.label-cyan-lighten-1 {
        background-color :  #26c6da;
        color : #000;
}
.bg-cyan-lighten-1 {
        background-color :  #26c6da;
}
.text-cyan-base, .text-cyan-base > i {
        color :       #00bcd4;
}
.label-cyan-base-white {
        background-color :       #00bcd4;
        color : #fff;
}
.label-cyan-base {
        background-color :       #00bcd4;
        color : #000;
}
.bg-cyan-base {
        background-color :       #00bcd4;
}
.text-cyan-darken-1, .text-cyan-darken-1 > i {
        color :   #00acc1;
}
.label-cyan-darken-1-white {
        background-color :   #00acc1;
        color : #fff;
}
.label-cyan-darken-1 {
        background-color :   #00acc1;
        color : #000;
}
.bg-cyan-darken-1 {
        background-color :   #00acc1;
}
.text-cyan-darken-2, .text-cyan-darken-2 > i {
        color :   #0097a7;
}
.label-cyan-darken-2-white {
        background-color :   #0097a7;
        color : #fff;
}
.label-cyan-darken-2 {
        background-color :   #0097a7;
        color : #000;
}
.bg-cyan-darken-2 {
        background-color :   #0097a7;
}
.text-cyan-darken-3, .text-cyan-darken-3 > i {
        color :   #00838f;
}
.label-cyan-darken-3-white {
        background-color :   #00838f;
        color : #fff;
}
.label-cyan-darken-3 {
        background-color :   #00838f;
        color : #000;
}
.bg-cyan-darken-3 {
        background-color :   #00838f;
}
.text-cyan-darken-4, .text-cyan-darken-4 > i {
        color :   #006064;
}
.label-cyan-darken-4-white {
        background-color :   #006064;
        color : #fff;
}
.label-cyan-darken-4 {
        background-color :   #006064;
        color : #000;
}
.bg-cyan-darken-4 {
        background-color :   #006064;
}
.text-cyan-accent-1, .text-cyan-accent-1 > i {
        color :   #84ffff;
}
.label-cyan-accent-1-white {
        background-color :   #84ffff;
        color : #fff;
}
.label-cyan-accent-1 {
        background-color :   #84ffff;
        color : #000;
}
.bg-cyan-accent-1 {
        background-color :   #84ffff;
}
.text-cyan-accent-2, .text-cyan-accent-2 > i {
        color :   #18ffff;
}
.label-cyan-accent-2-white {
        background-color :   #18ffff;
        color : #fff;
}
.label-cyan-accent-2 {
        background-color :   #18ffff;
        color : #000;
}
.bg-cyan-accent-2 {
        background-color :   #18ffff;
}
.text-cyan-accent-3, .text-cyan-accent-3 > i {
        color :   #00e5ff;
}
.label-cyan-accent-3-white {
        background-color :   #00e5ff;
        color : #fff;
}
.label-cyan-accent-3 {
        background-color :   #00e5ff;
        color : #000;
}
.bg-cyan-accent-3 {
        background-color :   #00e5ff;
}
.text-cyan-accent-4, .text-cyan-accent-4 > i {
        color :   #00b8d4;
}
.label-cyan-accent-4-white {
        background-color :   #00b8d4;
        color : #fff;
}
.label-cyan-accent-4 {
        background-color :   #00b8d4;
        color : #000;
}
.bg-cyan-accent-4 {
        background-color :   #00b8d4;
}
.text-teal-lighten-5, .text-teal-lighten-5 > i {
        color :  #e0f2f1;
}
.label-teal-lighten-5-white {
        background-color :  #e0f2f1;
        color : #fff;
}
.label-teal-lighten-5 {
        background-color :  #e0f2f1;
        color : #000;
}
.bg-teal-lighten-5 {
        background-color :  #e0f2f1;
}
.text-teal-lighten-4, .text-teal-lighten-4 > i {
        color :  #b2dfdb;
}
.label-teal-lighten-4-white {
        background-color :  #b2dfdb;
        color : #fff;
}
.label-teal-lighten-4 {
        background-color :  #b2dfdb;
        color : #000;
}
.bg-teal-lighten-4 {
        background-color :  #b2dfdb;
}
.text-teal-lighten-3, .text-teal-lighten-3 > i {
        color :  #80cbc4;
}
.label-teal-lighten-3-white {
        background-color :  #80cbc4;
        color : #fff;
}
.label-teal-lighten-3 {
        background-color :  #80cbc4;
        color : #000;
}
.bg-teal-lighten-3 {
        background-color :  #80cbc4;
}
.text-teal-lighten-2, .text-teal-lighten-2 > i {
        color :  #4db6ac;
}
.label-teal-lighten-2-white {
        background-color :  #4db6ac;
        color : #fff;
}
.label-teal-lighten-2 {
        background-color :  #4db6ac;
        color : #000;
}
.bg-teal-lighten-2 {
        background-color :  #4db6ac;
}
.text-teal-lighten-1, .text-teal-lighten-1 > i {
        color :  #26a69a;
}
.label-teal-lighten-1-white {
        background-color :  #26a69a;
        color : #fff;
}
.label-teal-lighten-1 {
        background-color :  #26a69a;
        color : #000;
}
.bg-teal-lighten-1 {
        background-color :  #26a69a;
}
.text-teal-base, .text-teal-base > i {
        color :       #009688;
}
.label-teal-base-white {
        background-color :       #009688;
        color : #fff;
}
.label-teal-base {
        background-color :       #009688;
        color : #000;
}
.bg-teal-base {
        background-color :       #009688;
}
.text-teal-darken-1, .text-teal-darken-1 > i {
        color :   #00897b;
}
.label-teal-darken-1-white {
        background-color :   #00897b;
        color : #fff;
}
.label-teal-darken-1 {
        background-color :   #00897b;
        color : #000;
}
.bg-teal-darken-1 {
        background-color :   #00897b;
}
.text-teal-darken-2, .text-teal-darken-2 > i {
        color :   #00796b;
}
.label-teal-darken-2-white {
        background-color :   #00796b;
        color : #fff;
}
.label-teal-darken-2 {
        background-color :   #00796b;
        color : #000;
}
.bg-teal-darken-2 {
        background-color :   #00796b;
}
.text-teal-darken-3, .text-teal-darken-3 > i {
        color :   #00695c;
}
.label-teal-darken-3-white {
        background-color :   #00695c;
        color : #fff;
}
.label-teal-darken-3 {
        background-color :   #00695c;
        color : #000;
}
.bg-teal-darken-3 {
        background-color :   #00695c;
}
.text-teal-darken-4, .text-teal-darken-4 > i {
        color :   #004d40;
}
.label-teal-darken-4-white {
        background-color :   #004d40;
        color : #fff;
}
.label-teal-darken-4 {
        background-color :   #004d40;
        color : #000;
}
.bg-teal-darken-4 {
        background-color :   #004d40;
}
.text-teal-accent-1, .text-teal-accent-1 > i {
        color :   #a7ffeb;
}
.label-teal-accent-1-white {
        background-color :   #a7ffeb;
        color : #fff;
}
.label-teal-accent-1 {
        background-color :   #a7ffeb;
        color : #000;
}
.bg-teal-accent-1 {
        background-color :   #a7ffeb;
}
.text-teal-accent-2, .text-teal-accent-2 > i {
        color :   #64ffda;
}
.label-teal-accent-2-white {
        background-color :   #64ffda;
        color : #fff;
}
.label-teal-accent-2 {
        background-color :   #64ffda;
        color : #000;
}
.bg-teal-accent-2 {
        background-color :   #64ffda;
}
.text-teal-accent-3, .text-teal-accent-3 > i {
        color :   #1de9b6;
}
.label-teal-accent-3-white {
        background-color :   #1de9b6;
        color : #fff;
}
.label-teal-accent-3 {
        background-color :   #1de9b6;
        color : #000;
}
.bg-teal-accent-3 {
        background-color :   #1de9b6;
}
.text-teal-accent-4, .text-teal-accent-4 > i {
        color :   #00bfa5;
}
.label-teal-accent-4-white {
        background-color :   #00bfa5;
        color : #fff;
}
.label-teal-accent-4 {
        background-color :   #00bfa5;
        color : #000;
}
.bg-teal-accent-4 {
        background-color :   #00bfa5;
}
.text-green-lighten-5, .text-green-lighten-5 > i {
        color :  #e8f5e9;
}
.label-green-lighten-5-white {
        background-color :  #e8f5e9;
        color : #fff;
}
.label-green-lighten-5 {
        background-color :  #e8f5e9;
        color : #000;
}
.bg-green-lighten-5 {
        background-color :  #e8f5e9;
}
.text-green-lighten-4, .text-green-lighten-4 > i {
        color :  #c8e6c9;
}
.label-green-lighten-4-white {
        background-color :  #c8e6c9;
        color : #fff;
}
.label-green-lighten-4 {
        background-color :  #c8e6c9;
        color : #000;
}
.bg-green-lighten-4 {
        background-color :  #c8e6c9;
}
.text-green-lighten-3, .text-green-lighten-3 > i {
        color :  #a5d6a7;
}
.label-green-lighten-3-white {
        background-color :  #a5d6a7;
        color : #fff;
}
.label-green-lighten-3 {
        background-color :  #a5d6a7;
        color : #000;
}
.bg-green-lighten-3 {
        background-color :  #a5d6a7;
}
.text-green-lighten-2, .text-green-lighten-2 > i {
        color :  #81c784;
}
.label-green-lighten-2-white {
        background-color :  #81c784;
        color : #fff;
}
.label-green-lighten-2 {
        background-color :  #81c784;
        color : #000;
}
.bg-green-lighten-2 {
        background-color :  #81c784;
}
.text-green-lighten-1, .text-green-lighten-1 > i {
        color :  #66bb6a;
}
.label-green-lighten-1-white {
        background-color :  #66bb6a;
        color : #fff;
}
.label-green-lighten-1 {
        background-color :  #66bb6a;
        color : #000;
}
.bg-green-lighten-1 {
        background-color :  #66bb6a;
}
.text-green-base, .text-green-base > i {
        color :       #4caf50;
}
.label-green-base-white {
        background-color :       #4caf50;
        color : #fff;
}
.label-green-base {
        background-color :       #4caf50;
        color : #000;
}
.bg-green-base {
        background-color :       #4caf50;
}
.text-green-darken-1, .text-green-darken-1 > i {
        color :   #43a047;
}
.label-green-darken-1-white {
        background-color :   #43a047;
        color : #fff;
}
.label-green-darken-1 {
        background-color :   #43a047;
        color : #000;
}
.bg-green-darken-1 {
        background-color :   #43a047;
}
.text-green-darken-2, .text-green-darken-2 > i {
        color :   #388e3c;
}
.label-green-darken-2-white {
        background-color :   #388e3c;
        color : #fff;
}
.label-green-darken-2 {
        background-color :   #388e3c;
        color : #000;
}
.bg-green-darken-2 {
        background-color :   #388e3c;
}
.text-green-darken-3, .text-green-darken-3 > i {
        color :   #2e7d32;
}
.label-green-darken-3-white {
        background-color :   #2e7d32;
        color : #fff;
}
.label-green-darken-3 {
        background-color :   #2e7d32;
        color : #000;
}
.bg-green-darken-3 {
        background-color :   #2e7d32;
}
.text-green-darken-4, .text-green-darken-4 > i {
        color :   #1b5e20;
}
.label-green-darken-4-white {
        background-color :   #1b5e20;
        color : #fff;
}
.label-green-darken-4 {
        background-color :   #1b5e20;
        color : #000;
}
.bg-green-darken-4 {
        background-color :   #1b5e20;
}
.text-green-accent-1, .text-green-accent-1 > i {
        color :   #b9f6ca;
}
.label-green-accent-1-white {
        background-color :   #b9f6ca;
        color : #fff;
}
.label-green-accent-1 {
        background-color :   #b9f6ca;
        color : #000;
}
.bg-green-accent-1 {
        background-color :   #b9f6ca;
}
.text-green-accent-2, .text-green-accent-2 > i {
        color :   #69f0ae;
}
.label-green-accent-2-white {
        background-color :   #69f0ae;
        color : #fff;
}
.label-green-accent-2 {
        background-color :   #69f0ae;
        color : #000;
}
.bg-green-accent-2 {
        background-color :   #69f0ae;
}
.text-green-accent-3, .text-green-accent-3 > i {
        color :   #00e676;
}
.label-green-accent-3-white {
        background-color :   #00e676;
        color : #fff;
}
.label-green-accent-3 {
        background-color :   #00e676;
        color : #000;
}
.bg-green-accent-3 {
        background-color :   #00e676;
}
.text-green-accent-4, .text-green-accent-4 > i {
        color :   #00c853;
}
.label-green-accent-4-white {
        background-color :   #00c853;
        color : #fff;
}
.label-green-accent-4 {
        background-color :   #00c853;
        color : #000;
}
.bg-green-accent-4 {
        background-color :   #00c853;
}
.text-light-green-lighten-5, .text-light-green-lighten-5 > i {
        color :  #f1f8e9;
}
.label-light-green-lighten-5-white {
        background-color :  #f1f8e9;
        color : #fff;
}
.label-light-green-lighten-5 {
        background-color :  #f1f8e9;
        color : #000;
}
.bg-light-green-lighten-5 {
        background-color :  #f1f8e9;
}
.text-light-green-lighten-4, .text-light-green-lighten-4 > i {
        color :  #dcedc8;
}
.label-light-green-lighten-4-white {
        background-color :  #dcedc8;
        color : #fff;
}
.label-light-green-lighten-4 {
        background-color :  #dcedc8;
        color : #000;
}
.bg-light-green-lighten-4 {
        background-color :  #dcedc8;
}
.text-light-green-lighten-3, .text-light-green-lighten-3 > i {
        color :  #c5e1a5;
}
.label-light-green-lighten-3-white {
        background-color :  #c5e1a5;
        color : #fff;
}
.label-light-green-lighten-3 {
        background-color :  #c5e1a5;
        color : #000;
}
.bg-light-green-lighten-3 {
        background-color :  #c5e1a5;
}
.text-light-green-lighten-2, .text-light-green-lighten-2 > i {
        color :  #aed581;
}
.label-light-green-lighten-2-white {
        background-color :  #aed581;
        color : #fff;
}
.label-light-green-lighten-2 {
        background-color :  #aed581;
        color : #000;
}
.bg-light-green-lighten-2 {
        background-color :  #aed581;
}
.text-light-green-lighten-1, .text-light-green-lighten-1 > i {
        color :  #9ccc65;
}
.label-light-green-lighten-1-white {
        background-color :  #9ccc65;
        color : #fff;
}
.label-light-green-lighten-1 {
        background-color :  #9ccc65;
        color : #000;
}
.bg-light-green-lighten-1 {
        background-color :  #9ccc65;
}
.text-light-green-base, .text-light-green-base > i {
        color :       #8bc34a;
}
.label-light-green-base-white {
        background-color :       #8bc34a;
        color : #fff;
}
.label-light-green-base {
        background-color :       #8bc34a;
        color : #000;
}
.bg-light-green-base {
        background-color :       #8bc34a;
}
.text-light-green-darken-1, .text-light-green-darken-1 > i {
        color :   #7cb342;
}
.label-light-green-darken-1-white {
        background-color :   #7cb342;
        color : #fff;
}
.label-light-green-darken-1 {
        background-color :   #7cb342;
        color : #000;
}
.bg-light-green-darken-1 {
        background-color :   #7cb342;
}
.text-light-green-darken-2, .text-light-green-darken-2 > i {
        color :   #689f38;
}
.label-light-green-darken-2-white {
        background-color :   #689f38;
        color : #fff;
}
.label-light-green-darken-2 {
        background-color :   #689f38;
        color : #000;
}
.bg-light-green-darken-2 {
        background-color :   #689f38;
}
.text-light-green-darken-3, .text-light-green-darken-3 > i {
        color :   #558b2f;
}
.label-light-green-darken-3-white {
        background-color :   #558b2f;
        color : #fff;
}
.label-light-green-darken-3 {
        background-color :   #558b2f;
        color : #000;
}
.bg-light-green-darken-3 {
        background-color :   #558b2f;
}
.text-light-green-darken-4, .text-light-green-darken-4 > i {
        color :   #33691e;
}
.label-light-green-darken-4-white {
        background-color :   #33691e;
        color : #fff;
}
.label-light-green-darken-4 {
        background-color :   #33691e;
        color : #000;
}
.bg-light-green-darken-4 {
        background-color :   #33691e;
}
.text-light-green-accent-1, .text-light-green-accent-1 > i {
        color :   #ccff90;
}
.label-light-green-accent-1-white {
        background-color :   #ccff90;
        color : #fff;
}
.label-light-green-accent-1 {
        background-color :   #ccff90;
        color : #000;
}
.bg-light-green-accent-1 {
        background-color :   #ccff90;
}
.text-light-green-accent-2, .text-light-green-accent-2 > i {
        color :   #b2ff59;
}
.label-light-green-accent-2-white {
        background-color :   #b2ff59;
        color : #fff;
}
.label-light-green-accent-2 {
        background-color :   #b2ff59;
        color : #000;
}
.bg-light-green-accent-2 {
        background-color :   #b2ff59;
}
.text-light-green-accent-3, .text-light-green-accent-3 > i {
        color :   #76ff03;
}
.label-light-green-accent-3-white {
        background-color :   #76ff03;
        color : #fff;
}
.label-light-green-accent-3 {
        background-color :   #76ff03;
        color : #000;
}
.bg-light-green-accent-3 {
        background-color :   #76ff03;
}
.text-light-green-accent-4, .text-light-green-accent-4 > i {
        color :   #64dd17;
}
.label-light-green-accent-4-white {
        background-color :   #64dd17;
        color : #fff;
}
.label-light-green-accent-4 {
        background-color :   #64dd17;
        color : #000;
}
.bg-light-green-accent-4 {
        background-color :   #64dd17;
}
.text-lime-lighten-5, .text-lime-lighten-5 > i {
        color :  #f9fbe7;
}
.label-lime-lighten-5-white {
        background-color :  #f9fbe7;
        color : #fff;
}
.label-lime-lighten-5 {
        background-color :  #f9fbe7;
        color : #000;
}
.bg-lime-lighten-5 {
        background-color :  #f9fbe7;
}
.text-lime-lighten-4, .text-lime-lighten-4 > i {
        color :  #f0f4c3;
}
.label-lime-lighten-4-white {
        background-color :  #f0f4c3;
        color : #fff;
}
.label-lime-lighten-4 {
        background-color :  #f0f4c3;
        color : #000;
}
.bg-lime-lighten-4 {
        background-color :  #f0f4c3;
}
.text-lime-lighten-3, .text-lime-lighten-3 > i {
        color :  #e6ee9c;
}
.label-lime-lighten-3-white {
        background-color :  #e6ee9c;
        color : #fff;
}
.label-lime-lighten-3 {
        background-color :  #e6ee9c;
        color : #000;
}
.bg-lime-lighten-3 {
        background-color :  #e6ee9c;
}
.text-lime-lighten-2, .text-lime-lighten-2 > i {
        color :  #dce775;
}
.label-lime-lighten-2-white {
        background-color :  #dce775;
        color : #fff;
}
.label-lime-lighten-2 {
        background-color :  #dce775;
        color : #000;
}
.bg-lime-lighten-2 {
        background-color :  #dce775;
}
.text-lime-lighten-1, .text-lime-lighten-1 > i {
        color :  #d4e157;
}
.label-lime-lighten-1-white {
        background-color :  #d4e157;
        color : #fff;
}
.label-lime-lighten-1 {
        background-color :  #d4e157;
        color : #000;
}
.bg-lime-lighten-1 {
        background-color :  #d4e157;
}
.text-lime-base, .text-lime-base > i {
        color :       #cddc39;
}
.label-lime-base-white {
        background-color :       #cddc39;
        color : #fff;
}
.label-lime-base {
        background-color :       #cddc39;
        color : #000;
}
.bg-lime-base {
        background-color :       #cddc39;
}
.text-lime-darken-1, .text-lime-darken-1 > i {
        color :   #c0ca33;
}
.label-lime-darken-1-white {
        background-color :   #c0ca33;
        color : #fff;
}
.label-lime-darken-1 {
        background-color :   #c0ca33;
        color : #000;
}
.bg-lime-darken-1 {
        background-color :   #c0ca33;
}
.text-lime-darken-2, .text-lime-darken-2 > i {
        color :   #afb42b;
}
.label-lime-darken-2-white {
        background-color :   #afb42b;
        color : #fff;
}
.label-lime-darken-2 {
        background-color :   #afb42b;
        color : #000;
}
.bg-lime-darken-2 {
        background-color :   #afb42b;
}
.text-lime-darken-3, .text-lime-darken-3 > i {
        color :   #9e9d24;
}
.label-lime-darken-3-white {
        background-color :   #9e9d24;
        color : #fff;
}
.label-lime-darken-3 {
        background-color :   #9e9d24;
        color : #000;
}
.bg-lime-darken-3 {
        background-color :   #9e9d24;
}
.text-lime-darken-4, .text-lime-darken-4 > i {
        color :   #827717;
}
.label-lime-darken-4-white {
        background-color :   #827717;
        color : #fff;
}
.label-lime-darken-4 {
        background-color :   #827717;
        color : #000;
}
.bg-lime-darken-4 {
        background-color :   #827717;
}
.text-lime-accent-1, .text-lime-accent-1 > i {
        color :   #f4ff81;
}
.label-lime-accent-1-white {
        background-color :   #f4ff81;
        color : #fff;
}
.label-lime-accent-1 {
        background-color :   #f4ff81;
        color : #000;
}
.bg-lime-accent-1 {
        background-color :   #f4ff81;
}
.text-lime-accent-2, .text-lime-accent-2 > i {
        color :   #eeff41;
}
.label-lime-accent-2-white {
        background-color :   #eeff41;
        color : #fff;
}
.label-lime-accent-2 {
        background-color :   #eeff41;
        color : #000;
}
.bg-lime-accent-2 {
        background-color :   #eeff41;
}
.text-lime-accent-3, .text-lime-accent-3 > i {
        color :   #c6ff00;
}
.label-lime-accent-3-white {
        background-color :   #c6ff00;
        color : #fff;
}
.label-lime-accent-3 {
        background-color :   #c6ff00;
        color : #000;
}
.bg-lime-accent-3 {
        background-color :   #c6ff00;
}
.text-lime-accent-4, .text-lime-accent-4 > i {
        color :   #aeea00;
}
.label-lime-accent-4-white {
        background-color :   #aeea00;
        color : #fff;
}
.label-lime-accent-4 {
        background-color :   #aeea00;
        color : #000;
}
.bg-lime-accent-4 {
        background-color :   #aeea00;
}
.text-yellow-lighten-5, .text-yellow-lighten-5 > i {
        color :  #fffde7;
}
.label-yellow-lighten-5-white {
        background-color :  #fffde7;
        color : #fff;
}
.label-yellow-lighten-5 {
        background-color :  #fffde7;
        color : #000;
}
.bg-yellow-lighten-5 {
        background-color :  #fffde7;
}
.text-yellow-lighten-4, .text-yellow-lighten-4 > i {
        color :  #fff9c4;
}
.label-yellow-lighten-4-white {
        background-color :  #fff9c4;
        color : #fff;
}
.label-yellow-lighten-4 {
        background-color :  #fff9c4;
        color : #000;
}
.bg-yellow-lighten-4 {
        background-color :  #fff9c4;
}
.text-yellow-lighten-3, .text-yellow-lighten-3 > i {
        color :  #fff59d;
}
.label-yellow-lighten-3-white {
        background-color :  #fff59d;
        color : #fff;
}
.label-yellow-lighten-3 {
        background-color :  #fff59d;
        color : #000;
}
.bg-yellow-lighten-3 {
        background-color :  #fff59d;
}
.text-yellow-lighten-2, .text-yellow-lighten-2 > i {
        color :  #fff176;
}
.label-yellow-lighten-2-white {
        background-color :  #fff176;
        color : #fff;
}
.label-yellow-lighten-2 {
        background-color :  #fff176;
        color : #000;
}
.bg-yellow-lighten-2 {
        background-color :  #fff176;
}
.text-yellow-lighten-1, .text-yellow-lighten-1 > i {
        color :  #ffee58;
}
.label-yellow-lighten-1-white {
        background-color :  #ffee58;
        color : #fff;
}
.label-yellow-lighten-1 {
        background-color :  #ffee58;
        color : #000;
}
.bg-yellow-lighten-1 {
        background-color :  #ffee58;
}
.text-yellow-base, .text-yellow-base > i {
        color :       #ffeb3b;
}
.label-yellow-base-white {
        background-color :       #ffeb3b;
        color : #fff;
}
.label-yellow-base {
        background-color :       #ffeb3b;
        color : #000;
}
.bg-yellow-base {
        background-color :       #ffeb3b;
}
.text-yellow-darken-1, .text-yellow-darken-1 > i {
        color :   #fdd835;
}
.label-yellow-darken-1-white {
        background-color :   #fdd835;
        color : #fff;
}
.label-yellow-darken-1 {
        background-color :   #fdd835;
        color : #000;
}
.bg-yellow-darken-1 {
        background-color :   #fdd835;
}
.text-yellow-darken-2, .text-yellow-darken-2 > i {
        color :   #fbc02d;
}
.label-yellow-darken-2-white {
        background-color :   #fbc02d;
        color : #fff;
}
.label-yellow-darken-2 {
        background-color :   #fbc02d;
        color : #000;
}
.bg-yellow-darken-2 {
        background-color :   #fbc02d;
}
.text-yellow-darken-3, .text-yellow-darken-3 > i {
        color :   #f9a825;
}
.label-yellow-darken-3-white {
        background-color :   #f9a825;
        color : #fff;
}
.label-yellow-darken-3 {
        background-color :   #f9a825;
        color : #000;
}
.bg-yellow-darken-3 {
        background-color :   #f9a825;
}
.text-yellow-darken-4, .text-yellow-darken-4 > i {
        color :   #f57f17;
}
.label-yellow-darken-4-white {
        background-color :   #f57f17;
        color : #fff;
}
.label-yellow-darken-4 {
        background-color :   #f57f17;
        color : #000;
}
.bg-yellow-darken-4 {
        background-color :   #f57f17;
}
.text-yellow-accent-1, .text-yellow-accent-1 > i {
        color :   #ffff8d;
}
.label-yellow-accent-1-white {
        background-color :   #ffff8d;
        color : #fff;
}
.label-yellow-accent-1 {
        background-color :   #ffff8d;
        color : #000;
}
.bg-yellow-accent-1 {
        background-color :   #ffff8d;
}
.text-yellow-accent-2, .text-yellow-accent-2 > i {
        color :   #ffff00;
}
.label-yellow-accent-2-white {
        background-color :   #ffff00;
        color : #fff;
}
.label-yellow-accent-2 {
        background-color :   #ffff00;
        color : #000;
}
.bg-yellow-accent-2 {
        background-color :   #ffff00;
}
.text-yellow-accent-3, .text-yellow-accent-3 > i {
        color :   #ffea00;
}
.label-yellow-accent-3-white {
        background-color :   #ffea00;
        color : #fff;
}
.label-yellow-accent-3 {
        background-color :   #ffea00;
        color : #000;
}
.bg-yellow-accent-3 {
        background-color :   #ffea00;
}
.text-yellow-accent-4, .text-yellow-accent-4 > i {
        color :   #ffd600;
}
.label-yellow-accent-4-white {
        background-color :   #ffd600;
        color : #fff;
}
.label-yellow-accent-4 {
        background-color :   #ffd600;
        color : #000;
}
.bg-yellow-accent-4 {
        background-color :   #ffd600;
}
.text-amber-lighten-5, .text-amber-lighten-5 > i {
        color :  #fff8e1;
}
.label-amber-lighten-5-white {
        background-color :  #fff8e1;
        color : #fff;
}
.label-amber-lighten-5 {
        background-color :  #fff8e1;
        color : #000;
}
.bg-amber-lighten-5 {
        background-color :  #fff8e1;
}
.text-amber-lighten-4, .text-amber-lighten-4 > i {
        color :  #ffecb3;
}
.label-amber-lighten-4-white {
        background-color :  #ffecb3;
        color : #fff;
}
.label-amber-lighten-4 {
        background-color :  #ffecb3;
        color : #000;
}
.bg-amber-lighten-4 {
        background-color :  #ffecb3;
}
.text-amber-lighten-3, .text-amber-lighten-3 > i {
        color :  #ffe082;
}
.label-amber-lighten-3-white {
        background-color :  #ffe082;
        color : #fff;
}
.label-amber-lighten-3 {
        background-color :  #ffe082;
        color : #000;
}
.bg-amber-lighten-3 {
        background-color :  #ffe082;
}
.text-amber-lighten-2, .text-amber-lighten-2 > i {
        color :  #ffd54f;
}
.label-amber-lighten-2-white {
        background-color :  #ffd54f;
        color : #fff;
}
.label-amber-lighten-2 {
        background-color :  #ffd54f;
        color : #000;
}
.bg-amber-lighten-2 {
        background-color :  #ffd54f;
}
.text-amber-lighten-1, .text-amber-lighten-1 > i {
        color :  #ffca28;
}
.label-amber-lighten-1-white {
        background-color :  #ffca28;
        color : #fff;
}
.label-amber-lighten-1 {
        background-color :  #ffca28;
        color : #000;
}
.bg-amber-lighten-1 {
        background-color :  #ffca28;
}
.text-amber-base, .text-amber-base > i {
        color :       #ffc107;
}
.label-amber-base-white {
        background-color :       #ffc107;
        color : #fff;
}
.label-amber-base {
        background-color :       #ffc107;
        color : #000;
}
.bg-amber-base {
        background-color :       #ffc107;
}
.text-amber-darken-1, .text-amber-darken-1 > i {
        color :   #ffb300;
}
.label-amber-darken-1-white {
        background-color :   #ffb300;
        color : #fff;
}
.label-amber-darken-1 {
        background-color :   #ffb300;
        color : #000;
}
.bg-amber-darken-1 {
        background-color :   #ffb300;
}
.text-amber-darken-2, .text-amber-darken-2 > i {
        color :   #ffa000;
}
.label-amber-darken-2-white {
        background-color :   #ffa000;
        color : #fff;
}
.label-amber-darken-2 {
        background-color :   #ffa000;
        color : #000;
}
.bg-amber-darken-2 {
        background-color :   #ffa000;
}
.text-amber-darken-3, .text-amber-darken-3 > i {
        color :   #ff8f00;
}
.label-amber-darken-3-white {
        background-color :   #ff8f00;
        color : #fff;
}
.label-amber-darken-3 {
        background-color :   #ff8f00;
        color : #000;
}
.bg-amber-darken-3 {
        background-color :   #ff8f00;
}
.text-amber-darken-4, .text-amber-darken-4 > i {
        color :   #ff6f00;
}
.label-amber-darken-4-white {
        background-color :   #ff6f00;
        color : #fff;
}
.label-amber-darken-4 {
        background-color :   #ff6f00;
        color : #000;
}
.bg-amber-darken-4 {
        background-color :   #ff6f00;
}
.text-amber-accent-1, .text-amber-accent-1 > i {
        color :   #ffe57f;
}
.label-amber-accent-1-white {
        background-color :   #ffe57f;
        color : #fff;
}
.label-amber-accent-1 {
        background-color :   #ffe57f;
        color : #000;
}
.bg-amber-accent-1 {
        background-color :   #ffe57f;
}
.text-amber-accent-2, .text-amber-accent-2 > i {
        color :   #ffd740;
}
.label-amber-accent-2-white {
        background-color :   #ffd740;
        color : #fff;
}
.label-amber-accent-2 {
        background-color :   #ffd740;
        color : #000;
}
.bg-amber-accent-2 {
        background-color :   #ffd740;
}
.text-amber-accent-3, .text-amber-accent-3 > i {
        color :   #ffc400;
}
.label-amber-accent-3-white {
        background-color :   #ffc400;
        color : #fff;
}
.label-amber-accent-3 {
        background-color :   #ffc400;
        color : #000;
}
.bg-amber-accent-3 {
        background-color :   #ffc400;
}
.text-amber-accent-4, .text-amber-accent-4 > i {
        color :   #ffab00;
}
.label-amber-accent-4-white {
        background-color :   #ffab00;
        color : #fff;
}
.label-amber-accent-4 {
        background-color :   #ffab00;
        color : #000;
}
.bg-amber-accent-4 {
        background-color :   #ffab00;
}
.text-orange-lighten-5, .text-orange-lighten-5 > i {
        color :  #fff3e0;
}
.label-orange-lighten-5-white {
        background-color :  #fff3e0;
        color : #fff;
}
.label-orange-lighten-5 {
        background-color :  #fff3e0;
        color : #000;
}
.bg-orange-lighten-5 {
        background-color :  #fff3e0;
}
.text-orange-lighten-4, .text-orange-lighten-4 > i {
        color :  #ffe0b2;
}
.label-orange-lighten-4-white {
        background-color :  #ffe0b2;
        color : #fff;
}
.label-orange-lighten-4 {
        background-color :  #ffe0b2;
        color : #000;
}
.bg-orange-lighten-4 {
        background-color :  #ffe0b2;
}
.text-orange-lighten-3, .text-orange-lighten-3 > i {
        color :  #ffcc80;
}
.label-orange-lighten-3-white {
        background-color :  #ffcc80;
        color : #fff;
}
.label-orange-lighten-3 {
        background-color :  #ffcc80;
        color : #000;
}
.bg-orange-lighten-3 {
        background-color :  #ffcc80;
}
.text-orange-lighten-2, .text-orange-lighten-2 > i {
        color :  #ffb74d;
}
.label-orange-lighten-2-white {
        background-color :  #ffb74d;
        color : #fff;
}
.label-orange-lighten-2 {
        background-color :  #ffb74d;
        color : #000;
}
.bg-orange-lighten-2 {
        background-color :  #ffb74d;
}
.text-orange-lighten-1, .text-orange-lighten-1 > i {
        color :  #ffa726;
}
.label-orange-lighten-1-white {
        background-color :  #ffa726;
        color : #fff;
}
.label-orange-lighten-1 {
        background-color :  #ffa726;
        color : #000;
}
.bg-orange-lighten-1 {
        background-color :  #ffa726;
}
.text-orange-base, .text-orange-base > i {
        color :       #ff9800;
}
.label-orange-base-white {
        background-color :       #ff9800;
        color : #fff;
}
.label-orange-base {
        background-color :       #ff9800;
        color : #000;
}
.bg-orange-base {
        background-color :       #ff9800;
}
.text-orange-darken-1, .text-orange-darken-1 > i {
        color :   #fb8c00;
}
.label-orange-darken-1-white {
        background-color :   #fb8c00;
        color : #fff;
}
.label-orange-darken-1 {
        background-color :   #fb8c00;
        color : #000;
}
.bg-orange-darken-1 {
        background-color :   #fb8c00;
}
.text-orange-darken-2, .text-orange-darken-2 > i {
        color :   #f57c00;
}
.label-orange-darken-2-white {
        background-color :   #f57c00;
        color : #fff;
}
.label-orange-darken-2 {
        background-color :   #f57c00;
        color : #000;
}
.bg-orange-darken-2 {
        background-color :   #f57c00;
}
.text-orange-darken-3, .text-orange-darken-3 > i {
        color :   #ef6c00;
}
.label-orange-darken-3-white {
        background-color :   #ef6c00;
        color : #fff;
}
.label-orange-darken-3 {
        background-color :   #ef6c00;
        color : #000;
}
.bg-orange-darken-3 {
        background-color :   #ef6c00;
}
.text-orange-darken-4, .text-orange-darken-4 > i {
        color :   #e65100;
}
.label-orange-darken-4-white {
        background-color :   #e65100;
        color : #fff;
}
.label-orange-darken-4 {
        background-color :   #e65100;
        color : #000;
}
.bg-orange-darken-4 {
        background-color :   #e65100;
}
.text-orange-accent-1, .text-orange-accent-1 > i {
        color :   #ffd180;
}
.label-orange-accent-1-white {
        background-color :   #ffd180;
        color : #fff;
}
.label-orange-accent-1 {
        background-color :   #ffd180;
        color : #000;
}
.bg-orange-accent-1 {
        background-color :   #ffd180;
}
.text-orange-accent-2, .text-orange-accent-2 > i {
        color :   #ffab40;
}
.label-orange-accent-2-white {
        background-color :   #ffab40;
        color : #fff;
}
.label-orange-accent-2 {
        background-color :   #ffab40;
        color : #000;
}
.bg-orange-accent-2 {
        background-color :   #ffab40;
}
.text-orange-accent-3, .text-orange-accent-3 > i {
        color :   #ff9100;
}
.label-orange-accent-3-white {
        background-color :   #ff9100;
        color : #fff;
}
.label-orange-accent-3 {
        background-color :   #ff9100;
        color : #000;
}
.bg-orange-accent-3 {
        background-color :   #ff9100;
}
.text-orange-accent-4, .text-orange-accent-4 > i {
        color :   #ff6d00;
}
.label-orange-accent-4-white {
        background-color :   #ff6d00;
        color : #fff;
}
.label-orange-accent-4 {
        background-color :   #ff6d00;
        color : #000;
}
.bg-orange-accent-4 {
        background-color :   #ff6d00;
}
.text-deep-orange-lighten-5, .text-deep-orange-lighten-5 > i {
        color :  #fbe9e7;
}
.label-deep-orange-lighten-5-white {
        background-color :  #fbe9e7;
        color : #fff;
}
.label-deep-orange-lighten-5 {
        background-color :  #fbe9e7;
        color : #000;
}
.bg-deep-orange-lighten-5 {
        background-color :  #fbe9e7;
}
.text-deep-orange-lighten-4, .text-deep-orange-lighten-4 > i {
        color :  #ffccbc;
}
.label-deep-orange-lighten-4-white {
        background-color :  #ffccbc;
        color : #fff;
}
.label-deep-orange-lighten-4 {
        background-color :  #ffccbc;
        color : #000;
}
.bg-deep-orange-lighten-4 {
        background-color :  #ffccbc;
}
.text-deep-orange-lighten-3, .text-deep-orange-lighten-3 > i {
        color :  #ffab91;
}
.label-deep-orange-lighten-3-white {
        background-color :  #ffab91;
        color : #fff;
}
.label-deep-orange-lighten-3 {
        background-color :  #ffab91;
        color : #000;
}
.bg-deep-orange-lighten-3 {
        background-color :  #ffab91;
}
.text-deep-orange-lighten-2, .text-deep-orange-lighten-2 > i {
        color :  #ff8a65;
}
.label-deep-orange-lighten-2-white {
        background-color :  #ff8a65;
        color : #fff;
}
.label-deep-orange-lighten-2 {
        background-color :  #ff8a65;
        color : #000;
}
.bg-deep-orange-lighten-2 {
        background-color :  #ff8a65;
}
.text-deep-orange-lighten-1, .text-deep-orange-lighten-1 > i {
        color :  #ff7043;
}
.label-deep-orange-lighten-1-white {
        background-color :  #ff7043;
        color : #fff;
}
.label-deep-orange-lighten-1 {
        background-color :  #ff7043;
        color : #000;
}
.bg-deep-orange-lighten-1 {
        background-color :  #ff7043;
}
.text-deep-orange-base, .text-deep-orange-base > i {
        color :       #ff5722;
}
.label-deep-orange-base-white {
        background-color :       #ff5722;
        color : #fff;
}
.label-deep-orange-base {
        background-color :       #ff5722;
        color : #000;
}
.bg-deep-orange-base {
        background-color :       #ff5722;
}
.text-deep-orange-darken-1, .text-deep-orange-darken-1 > i {
        color :   #f4511e;
}
.label-deep-orange-darken-1-white {
        background-color :   #f4511e;
        color : #fff;
}
.label-deep-orange-darken-1 {
        background-color :   #f4511e;
        color : #000;
}
.bg-deep-orange-darken-1 {
        background-color :   #f4511e;
}
.text-deep-orange-darken-2, .text-deep-orange-darken-2 > i {
        color :   #e64a19;
}
.label-deep-orange-darken-2-white {
        background-color :   #e64a19;
        color : #fff;
}
.label-deep-orange-darken-2 {
        background-color :   #e64a19;
        color : #000;
}
.bg-deep-orange-darken-2 {
        background-color :   #e64a19;
}
.text-deep-orange-darken-3, .text-deep-orange-darken-3 > i {
        color :   #d84315;
}
.label-deep-orange-darken-3-white {
        background-color :   #d84315;
        color : #fff;
}
.label-deep-orange-darken-3 {
        background-color :   #d84315;
        color : #000;
}
.bg-deep-orange-darken-3 {
        background-color :   #d84315;
}
.text-deep-orange-darken-4, .text-deep-orange-darken-4 > i {
        color :   #bf360c;
}
.label-deep-orange-darken-4-white {
        background-color :   #bf360c;
        color : #fff;
}
.label-deep-orange-darken-4 {
        background-color :   #bf360c;
        color : #000;
}
.bg-deep-orange-darken-4 {
        background-color :   #bf360c;
}
.text-deep-orange-accent-1, .text-deep-orange-accent-1 > i {
        color :   #ff9e80;
}
.label-deep-orange-accent-1-white {
        background-color :   #ff9e80;
        color : #fff;
}
.label-deep-orange-accent-1 {
        background-color :   #ff9e80;
        color : #000;
}
.bg-deep-orange-accent-1 {
        background-color :   #ff9e80;
}
.text-deep-orange-accent-2, .text-deep-orange-accent-2 > i {
        color :   #ff6e40;
}
.label-deep-orange-accent-2-white {
        background-color :   #ff6e40;
        color : #fff;
}
.label-deep-orange-accent-2 {
        background-color :   #ff6e40;
        color : #000;
}
.bg-deep-orange-accent-2 {
        background-color :   #ff6e40;
}
.text-deep-orange-accent-3, .text-deep-orange-accent-3 > i {
        color :   #ff3d00;
}
.label-deep-orange-accent-3-white {
        background-color :   #ff3d00;
        color : #fff;
}
.label-deep-orange-accent-3 {
        background-color :   #ff3d00;
        color : #000;
}
.bg-deep-orange-accent-3 {
        background-color :   #ff3d00;
}
.text-deep-orange-accent-4, .text-deep-orange-accent-4 > i {
        color :   #dd2c00;
}
.label-deep-orange-accent-4-white {
        background-color :   #dd2c00;
        color : #fff;
}
.label-deep-orange-accent-4 {
        background-color :   #dd2c00;
        color : #000;
}
.bg-deep-orange-accent-4 {
        background-color :   #dd2c00;
}
.text-brown-lighten-5, .text-brown-lighten-5 > i {
        color :  #efebe9;
}
.label-brown-lighten-5-white {
        background-color :  #efebe9;
        color : #fff;
}
.label-brown-lighten-5 {
        background-color :  #efebe9;
        color : #000;
}
.bg-brown-lighten-5 {
        background-color :  #efebe9;
}
.text-brown-lighten-4, .text-brown-lighten-4 > i {
        color :  #d7ccc8;
}
.label-brown-lighten-4-white {
        background-color :  #d7ccc8;
        color : #fff;
}
.label-brown-lighten-4 {
        background-color :  #d7ccc8;
        color : #000;
}
.bg-brown-lighten-4 {
        background-color :  #d7ccc8;
}
.text-brown-lighten-3, .text-brown-lighten-3 > i {
        color :  #bcaaa4;
}
.label-brown-lighten-3-white {
        background-color :  #bcaaa4;
        color : #fff;
}
.label-brown-lighten-3 {
        background-color :  #bcaaa4;
        color : #000;
}
.bg-brown-lighten-3 {
        background-color :  #bcaaa4;
}
.text-brown-lighten-2, .text-brown-lighten-2 > i {
        color :  #a1887f;
}
.label-brown-lighten-2-white {
        background-color :  #a1887f;
        color : #fff;
}
.label-brown-lighten-2 {
        background-color :  #a1887f;
        color : #000;
}
.bg-brown-lighten-2 {
        background-color :  #a1887f;
}
.text-brown-lighten-1, .text-brown-lighten-1 > i {
        color :  #8d6e63;
}
.label-brown-lighten-1-white {
        background-color :  #8d6e63;
        color : #fff;
}
.label-brown-lighten-1 {
        background-color :  #8d6e63;
        color : #000;
}
.bg-brown-lighten-1 {
        background-color :  #8d6e63;
}
.text-brown-base, .text-brown-base > i {
        color :       #795548;
}
.label-brown-base-white {
        background-color :       #795548;
        color : #fff;
}
.label-brown-base {
        background-color :       #795548;
        color : #000;
}
.bg-brown-base {
        background-color :       #795548;
}
.text-brown-darken-1, .text-brown-darken-1 > i {
        color :   #6d4c41;
}
.label-brown-darken-1-white {
        background-color :   #6d4c41;
        color : #fff;
}
.label-brown-darken-1 {
        background-color :   #6d4c41;
        color : #000;
}
.bg-brown-darken-1 {
        background-color :   #6d4c41;
}
.text-brown-darken-2, .text-brown-darken-2 > i {
        color :   #5d4037;
}
.label-brown-darken-2-white {
        background-color :   #5d4037;
        color : #fff;
}
.label-brown-darken-2 {
        background-color :   #5d4037;
        color : #000;
}
.bg-brown-darken-2 {
        background-color :   #5d4037;
}
.text-brown-darken-3, .text-brown-darken-3 > i {
        color :   #4e342e;
}
.label-brown-darken-3-white {
        background-color :   #4e342e;
        color : #fff;
}
.label-brown-darken-3 {
        background-color :   #4e342e;
        color : #000;
}
.bg-brown-darken-3 {
        background-color :   #4e342e;
}
.text-brown-darken-4, .text-brown-darken-4 > i {
        color :   #3e2723;
}
.label-brown-darken-4-white {
        background-color :   #3e2723;
        color : #fff;
}
.label-brown-darken-4 {
        background-color :   #3e2723;
        color : #000;
}
.bg-brown-darken-4 {
        background-color :   #3e2723;
}
.text-blue-grey-lighten-5, .text-blue-grey-lighten-5 > i {
        color :  #eceff1;
}
.label-blue-grey-lighten-5-white {
        background-color :  #eceff1;
        color : #fff;
}
.label-blue-grey-lighten-5 {
        background-color :  #eceff1;
        color : #000;
}
.bg-blue-grey-lighten-5 {
        background-color :  #eceff1;
}
.text-blue-grey-lighten-4, .text-blue-grey-lighten-4 > i {
        color :  #cfd8dc;
}
.label-blue-grey-lighten-4-white {
        background-color :  #cfd8dc;
        color : #fff;
}
.label-blue-grey-lighten-4 {
        background-color :  #cfd8dc;
        color : #000;
}
.bg-blue-grey-lighten-4 {
        background-color :  #cfd8dc;
}
.text-blue-grey-lighten-3, .text-blue-grey-lighten-3 > i {
        color :  #b0bec5;
}
.label-blue-grey-lighten-3-white {
        background-color :  #b0bec5;
        color : #fff;
}
.label-blue-grey-lighten-3 {
        background-color :  #b0bec5;
        color : #000;
}
.bg-blue-grey-lighten-3 {
        background-color :  #b0bec5;
}
.text-blue-grey-lighten-2, .text-blue-grey-lighten-2 > i {
        color :  #90a4ae;
}
.label-blue-grey-lighten-2-white {
        background-color :  #90a4ae;
        color : #fff;
}
.label-blue-grey-lighten-2 {
        background-color :  #90a4ae;
        color : #000;
}
.bg-blue-grey-lighten-2 {
        background-color :  #90a4ae;
}
.text-blue-grey-lighten-1, .text-blue-grey-lighten-1 > i {
        color :  #78909c;
}
.label-blue-grey-lighten-1-white {
        background-color :  #78909c;
        color : #fff;
}
.label-blue-grey-lighten-1 {
        background-color :  #78909c;
        color : #000;
}
.bg-blue-grey-lighten-1 {
        background-color :  #78909c;
}
.text-blue-grey-base, .text-blue-grey-base > i {
        color :       #607d8b;
}
.label-blue-grey-base-white {
        background-color :       #607d8b;
        color : #fff;
}
.label-blue-grey-base {
        background-color :       #607d8b;
        color : #000;
}
.bg-blue-grey-base {
        background-color :       #607d8b;
}
.text-blue-grey-darken-1, .text-blue-grey-darken-1 > i {
        color :   #546e7a;
}
.label-blue-grey-darken-1-white {
        background-color :   #546e7a;
        color : #fff;
}
.label-blue-grey-darken-1 {
        background-color :   #546e7a;
        color : #000;
}
.bg-blue-grey-darken-1 {
        background-color :   #546e7a;
}
.text-blue-grey-darken-2, .text-blue-grey-darken-2 > i {
        color :   #455a64;
}
.label-blue-grey-darken-2-white {
        background-color :   #455a64;
        color : #fff;
}
.label-blue-grey-darken-2 {
        background-color :   #455a64;
        color : #000;
}
.bg-blue-grey-darken-2 {
        background-color :   #455a64;
}
.text-blue-grey-darken-3, .text-blue-grey-darken-3 > i {
        color :   #37474f;
}
.label-blue-grey-darken-3-white {
        background-color :   #37474f;
        color : #fff;
}
.label-blue-grey-darken-3 {
        background-color :   #37474f;
        color : #000;
}
.bg-blue-grey-darken-3 {
        background-color :   #37474f;
}
.text-blue-grey-darken-4, .text-blue-grey-darken-4 > i {
        color :   #263238;
}
.label-blue-grey-darken-4-white {
        background-color :   #263238;
        color : #fff;
}
.label-blue-grey-darken-4 {
        background-color :   #263238;
        color : #000;
}
.bg-blue-grey-darken-4 {
        background-color :   #263238;
}
.text-grey-lighten-5, .text-grey-lighten-5 > i {
        color :  #fafafa;
}
.label-grey-lighten-5-white {
        background-color :  #fafafa;
        color : #fff;
}
.label-grey-lighten-5 {
        background-color :  #fafafa;
        color : #000;
}
.bg-grey-lighten-5 {
        background-color :  #fafafa;
}
.text-grey-lighten-4, .text-grey-lighten-4 > i {
        color :  #f5f5f5;
}
.label-grey-lighten-4-white {
        background-color :  #f5f5f5;
        color : #fff;
}
.label-grey-lighten-4 {
        background-color :  #f5f5f5;
        color : #000;
}
.bg-grey-lighten-4 {
        background-color :  #f5f5f5;
}
.text-grey-lighten-3, .text-grey-lighten-3 > i {
        color :  #eeeeee;
}
.label-grey-lighten-3-white {
        background-color :  #eeeeee;
        color : #fff;
}
.label-grey-lighten-3 {
        background-color :  #eeeeee;
        color : #000;
}
.bg-grey-lighten-3 {
        background-color :  #eeeeee;
}
.text-grey-lighten-2, .text-grey-lighten-2 > i {
        color :  #e0e0e0;
}
.label-grey-lighten-2-white {
        background-color :  #e0e0e0;
        color : #fff;
}
.label-grey-lighten-2 {
        background-color :  #e0e0e0;
        color : #000;
}
.bg-grey-lighten-2 {
        background-color :  #e0e0e0;
}
.text-grey-lighten-1, .text-grey-lighten-1 > i {
        color :  #bdbdbd;
}
.label-grey-lighten-1-white {
        background-color :  #bdbdbd;
        color : #fff;
}
.label-grey-lighten-1 {
        background-color :  #bdbdbd;
        color : #000;
}
.bg-grey-lighten-1 {
        background-color :  #bdbdbd;
}
.text-grey-base, .text-grey-base > i {
        color :       #9e9e9e;
}
.label-grey-base-white {
        background-color :       #9e9e9e;
        color : #fff;
}
.label-grey-base {
        background-color :       #9e9e9e;
        color : #000;
}
.bg-grey-base {
        background-color :       #9e9e9e;
}
.text-grey-darken-1, .text-grey-darken-1 > i {
        color :   #757575;
}
.label-grey-darken-1-white {
        background-color :   #757575;
        color : #fff;
}
.label-grey-darken-1 {
        background-color :   #757575;
        color : #000;
}
.bg-grey-darken-1 {
        background-color :   #757575;
}
.text-grey-darken-2, .text-grey-darken-2 > i {
        color :   #616161;
}
.label-grey-darken-2-white {
        background-color :   #616161;
        color : #fff;
}
.label-grey-darken-2 {
        background-color :   #616161;
        color : #000;
}
.bg-grey-darken-2 {
        background-color :   #616161;
}
.text-grey-darken-3, .text-grey-darken-3 > i {
        color :   #424242;
}
.label-grey-darken-3-white {
        background-color :   #424242;
        color : #fff;
}
.label-grey-darken-3 {
        background-color :   #424242;
        color : #000;
}
.bg-grey-darken-3 {
        background-color :   #424242;
}
.text-grey-darken-4, .text-grey-darken-4 > i {
        color :   #212121;
}
.label-grey-darken-4-white {
        background-color :   #212121;
        color : #fff;
}
.label-grey-darken-4 {
        background-color :   #212121;
        color : #000;
}
.bg-grey-darken-4 {
        background-color :   #212121;
}
.text-black-base, .text-black-base > i {
        color : #000;
}
.label-black-base-white {
        background-color : #000;
        color : #fff;
}
.label-black-base {
        background-color : #000;
        color : #000;
}
.bg-black-base {
        background-color : #000;
}
.text-white-base, .text-white-base > i {
        color : #fff;
}
.label-white-base-white {
        background-color : #fff;
        color : #fff;
}
.label-white-base {
        background-color : #fff;
        color : #000;
}
.bg-white-base {
        background-color : #fff;
}
.text-foggy-grey, .text-foggy-grey > i {
        color : #4f4f4f;
}
.label-foggy-grey-white {
        background-color : #4f4f4f;
        color : #fff;
}
.label-foggy-grey {
        background-color : #4f4f4f;
        color : #000;
}
.bg-foggy-grey {
        background-color : #4f4f4f;
}
.text-primary-color, .text-primary-color > i {
        color :        #4285f4;
}
.label-primary-color-white {
        background-color :        #4285f4;
        color : #fff;
}
.label-primary-color {
        background-color :        #4285f4;
        color : #000;
}
.bg-primary-color {
        background-color :        #4285f4;
}
.text-primary-color-dark, .text-primary-color-dark > i {
        color :   #0d47a1;
}
.label-primary-color-dark-white {
        background-color :   #0d47a1;
        color : #fff;
}
.label-primary-color-dark {
        background-color :   #0d47a1;
        color : #000;
}
.bg-primary-color-dark {
        background-color :   #0d47a1;
}
.text-secondary-color, .text-secondary-color > i {
        color :      #aa66cc;
}
.label-secondary-color-white {
        background-color :      #aa66cc;
        color : #fff;
}
.label-secondary-color {
        background-color :      #aa66cc;
        color : #000;
}
.bg-secondary-color {
        background-color :      #aa66cc;
}
.text-secondary-color-dark, .text-secondary-color-dark > i {
        color : #9933cc;
}
.label-secondary-color-dark-white {
        background-color : #9933cc;
        color : #fff;
}
.label-secondary-color-dark {
        background-color : #9933cc;
        color : #000;
}
.bg-secondary-color-dark {
        background-color : #9933cc;
}
.text-default-color, .text-default-color > i {
        color :        #2bbbad;
}
.label-default-color-white {
        background-color :        #2bbbad;
        color : #fff;
}
.label-default-color {
        background-color :        #2bbbad;
        color : #000;
}
.bg-default-color {
        background-color :        #2bbbad;
}
.text-default-color-dark, .text-default-color-dark > i {
        color :   #00695c;
}
.label-default-color-dark-white {
        background-color :   #00695c;
        color : #fff;
}
.label-default-color-dark {
        background-color :   #00695c;
        color : #000;
}
.bg-default-color-dark {
        background-color :   #00695c;
}
.text-info-color, .text-info-color > i {
        color :           #33b5e5;
}
.label-info-color-white {
        background-color :           #33b5e5;
        color : #fff;
        &:hover {
                background-color : #3365e5;
        }
}
.label-info-color {
        background-color :           #33b5e5;
        color : #000;
}
.bg-info-color {
        background-color :           #33b5e5;
}
.text-info-color-dark, .text-info-color-dark > i {
        color :      #0099cc;
}
.label-info-color-dark-white {
        background-color :      #0099cc;
        color : #fff;
}
.label-info-color-dark {
        background-color :      #0099cc;
        color : #000;
}
.bg-info-color-dark {
        background-color :      #0099cc;
}
.text-success-color, .text-success-color > i {
        color :        #00c851;
}
.label-success-color-white {
        background-color :        #00c851;
        color : #fff;
}
.label-success-color {
        background-color :        #00c851;
        color : #000;
}
.bg-success-color {
        background-color :        #00c851;
}
.text-success-color-dark, .text-success-color-dark > i {
        color :   #007e33;
}
.label-success-color-dark-white {
        background-color :   #007e33;
        color : #fff;
}
.label-success-color-dark {
        background-color :   #007e33;
        color : #000;
}
.bg-success-color-dark {
        background-color :   #007e33;
}
.text-warning-color, .text-warning-color > i {
        color :        #ffbb33;
}
.label-warning-color-white {
        background-color :        #ffbb33;
        color : #fff;
}
.label-warning-color {
        background-color :        #ffbb33;
        color : #000;
}
.bg-warning-color {
        background-color :        #ffbb33;
}
.text-warning-color-dark, .text-warning-color-dark > i {
        color :   #ff8800;
}
.label-warning-color-dark-white {
        background-color :   #ff8800;
        color : #fff;
}
.label-warning-color-dark {
        background-color :   #ff8800;
        color : #000;
}
.bg-warning-color-dark {
        background-color :   #ff8800;
}
.text-danger-color, .text-danger-color > i {
        color :         #ff3547;
}
.label-danger-color-white {
        background-color :         #ff3547;
        color : #fff;
}
.label-danger-color {
        background-color :         #ff3547;
        color : #000;
}
.bg-danger-color {
        background-color :         #ff3547;
}
.text-danger-color-dark, .text-danger-color-dark > i {
        color :    #cc0000;
}
.label-danger-color-dark-white {
        background-color :    #cc0000;
        color : #fff;
}
.label-danger-color-dark {
        background-color :    #cc0000;
        color : #000;
}
.bg-danger-color-dark {
        background-color :    #cc0000;
}
.text-elegant-color, .text-elegant-color > i {
        color :        #2e2e2e;
}
.label-elegant-color-white {
        background-color :        #2e2e2e;
        color : #fff;
}
.label-elegant-color {
        background-color :        #2e2e2e;
        color : #000;
}
.bg-elegant-color {
        background-color :        #2e2e2e;
}
.text-elegant-color-dark, .text-elegant-color-dark > i {
        color :   #212121;
}
.label-elegant-color-dark-white {
        background-color :   #212121;
        color : #fff;
}
.label-elegant-color-dark {
        background-color :   #212121;
        color : #000;
}
.bg-elegant-color-dark {
        background-color :   #212121;
}
.text-stylish-color, .text-stylish-color > i {
        color :        #4b515d;
}
.label-stylish-color-white {
        background-color :        #4b515d;
        color : #fff;
}
.label-stylish-color {
        background-color :        #4b515d;
        color : #000;
}
.bg-stylish-color {
        background-color :        #4b515d;
}
.text-stylish-color-dark, .text-stylish-color-dark > i {
        color :   #3e4551;
}
.label-stylish-color-dark-white {
        background-color :   #3e4551;
        color : #fff;
}
.label-stylish-color-dark {
        background-color :   #3e4551;
        color : #000;
}
.bg-stylish-color-dark {
        background-color :   #3e4551;
}
.text-unique-color, .text-unique-color > i {
        color :         #3f729b;
}
.label-unique-color-white {
        background-color :         #3f729b;
        color : #fff;
}
.label-unique-color {
        background-color :         #3f729b;
        color : #000;
}
.bg-unique-color {
        background-color :         #3f729b;
}
.text-unique-color-dark, .text-unique-color-dark > i {
        color :    #1c2331;
}
.label-unique-color-dark-white {
        background-color :    #1c2331;
        color : #fff;
}
.label-unique-color-dark {
        background-color :    #1c2331;
        color : #000;
}
.bg-unique-color-dark {
        background-color :    #1c2331;
}
.text-special-color, .text-special-color > i {
        color :        #37474f;
}
.label-special-color-white {
        background-color :        #37474f;
        color : #fff;
}
.label-special-color {
        background-color :        #37474f;
        color : #000;
}
.bg-special-color {
        background-color :        #37474f;
}
.text-special-color-dark, .text-special-color-dark > i {
        color :   #263238;
}
.label-special-color-dark-white {
        background-color :   #263238;
        color : #fff;
}
.label-special-color-dark {
        background-color :   #263238;
        color : #000;
}
.bg-special-color-dark {
        background-color :   #263238;
}
.text-white, .text-white > i {
        color :                #fff;
}
.label-white-white {
        background-color :                #fff;
        color : #fff;
}
.label-white {
        background-color :                #fff;
        color : #000;
}
.bg-white {
        background-color :                #fff;
}
.text-black, .text-black > i {
        color :                #000;
}
.label-black-white {
        background-color :                #000;
        color : #fff;
}
.label-black {
        background-color :                #000;
        color : #000;
}
.bg-black {
        background-color :                #000;
}
.text-fb-color, .text-fb-color > i {
        color :      #3b5998;
}
.label-fb-color-white {
        background-color :      #3b5998;
        color : #fff;
}
.label-fb-color {
        background-color :      #3b5998;
        color : #000;
}
.bg-fb-color {
        background-color :      #3b5998;
}
.text-tw-color, .text-tw-color > i {
        color :      #55acee;
}
.label-tw-color-white {
        background-color :      #55acee;
        color : #fff;
}
.label-tw-color {
        background-color :      #55acee;
        color : #000;
}
.bg-tw-color {
        background-color :      #55acee;
}
.text-gplus-color, .text-gplus-color > i {
        color :   #dd4b39;
}
.label-gplus-color-white {
        background-color :   #dd4b39;
        color : #fff;
}
.label-gplus-color {
        background-color :   #dd4b39;
        color : #000;
}
.bg-gplus-color {
        background-color :   #dd4b39;
}
.text-yt-color, .text-yt-color > i {
        color :      #ed302f;
}
.label-yt-color-white {
        background-color :      #ed302f;
        color : #fff;
}
.label-yt-color {
        background-color :      #ed302f;
        color : #000;
}
.bg-yt-color {
        background-color :      #ed302f;
}
.text-li-color, .text-li-color > i {
        color :      #0082ca;
}
.label-li-color-white {
        background-color :      #0082ca;
        color : #fff;
}
.label-li-color {
        background-color :      #0082ca;
        color : #000;
}
.bg-li-color {
        background-color :      #0082ca;
}
.text-pin-color, .text-pin-color > i {
        color :     #c61118;
}
.label-pin-color-white {
        background-color :     #c61118;
        color : #fff;
}
.label-pin-color {
        background-color :     #c61118;
        color : #000;
}
.bg-pin-color {
        background-color :     #c61118;
}
.text-ins-color, .text-ins-color > i {
        color :     #2e5e86;
}
.label-ins-color-white {
        background-color :     #2e5e86;
        color : #fff;
}
.label-ins-color {
        background-color :     #2e5e86;
        color : #000;
}
.bg-ins-color {
        background-color :     #2e5e86;
}
.text-git-color, .text-git-color > i {
        color :     #333333;
}
.label-git-color-white {
        background-color :     #333333;
        color : #fff;
}
.label-git-color {
        background-color :     #333333;
        color : #000;
}
.bg-git-color {
        background-color :     #333333;
}
.text-comm-color, .text-comm-color > i {
        color :    #30cfc0;
}
.label-comm-color-white {
        background-color :    #30cfc0;
        color : #fff;
}
.label-comm-color {
        background-color :    #30cfc0;
        color : #000;
}
.bg-comm-color {
        background-color :    #30cfc0;
}
.text-vk-color, .text-vk-color > i {
        color :      #4c75a3;
}
.label-vk-color-white {
        background-color :      #4c75a3;
        color : #fff;
}
.label-vk-color {
        background-color :      #4c75a3;
        color : #000;
}
.bg-vk-color {
        background-color :      #4c75a3;
}
.text-drib-color, .text-drib-color > i {
        color :    #ec4a89;
}
.label-drib-color-white {
        background-color :    #ec4a89;
        color : #fff;
}
.label-drib-color {
        background-color :    #ec4a89;
        color : #000;
}
.bg-drib-color {
        background-color :    #ec4a89;
}
.text-so-color, .text-so-color > i {
        color :      #ffac44;
}
.label-so-color-white {
        background-color :      #ffac44;
        color : #fff;
}
.label-so-color {
        background-color :      #ffac44;
        color : #000;
}
.bg-so-color {
        background-color :      #ffac44;
}
.text-slack-color, .text-slack-color > i {
        color :   #56b68b;
}
.label-slack-color-white {
        background-color :   #56b68b;
        color : #fff;
}
.label-slack-color {
        background-color :   #56b68b;
        color : #000;
}
.bg-slack-color {
        background-color :   #56b68b;
}
.text-email-color, .text-email-color > i {
        color :   #4b515d;
}
.label-email-color-white {
        background-color :   #4b515d;
        color : #fff;
}
.label-email-color {
        background-color :   #4b515d;
        color : #000;
}
.bg-email-color {
        background-color :   #4b515d;
}
.text-redd-color, .text-redd-color > i {
        color :    #ff4500;
}
.label-redd-color-white {
        background-color :    #ff4500;
        color : #fff;
}
.label-redd-color {
        background-color :    #ff4500;
        color : #000;
}
.bg-redd-color {
        background-color :    #ff4500;
}
.text-twitch-color, .text-twitch-color > i {
        color :  #6441a4;
}
.label-twitch-color-white {
        background-color :  #6441a4;
        color : #fff;
}
.label-twitch-color {
        background-color :  #6441a4;
        color : #000;
}
.bg-twitch-color {
        background-color :  #6441a4;
}
.text-discord-color, .text-discord-color > i {
        color : #7289da;
}
.label-discord-color-white {
        background-color : #7289da;
        color : #fff;
}
.label-discord-color {
        background-color : #7289da;
        color : #000;
}
.bg-discord-color {
        background-color : #7289da;
}
.text-switch-color-unchecked-bg, .text-switch-color-unchecked-bg > i {
        color :       #F1F1F1;
}
.label-switch-color-unchecked-bg-white {
        background-color :       #F1F1F1;
        color : #fff;
}
.label-switch-color-unchecked-bg {
        background-color :       #F1F1F1;
        color : #000;
}
.bg-switch-color-unchecked-bg {
        background-color :       #F1F1F1;
}
.text-switch-color-unchecked-lever-bg, .text-switch-color-unchecked-lever-bg > i {
        color : #818181;
}
.label-switch-color-unchecked-lever-bg-white {
        background-color : #818181;
        color : #fff;
}
.label-switch-color-unchecked-lever-bg {
        background-color : #818181;
        color : #000;
}
.bg-switch-color-unchecked-lever-bg {
        background-color : #818181;
}
.label-purple-white {
        background-image : linear-gradient(8deg, #ff6ec4,  #7873f5);
        color : #fff;
}
.label-purple {
        background-image : linear-gradient(8deg, #ff6ec4,  #7873f5);
        color : #000;
}
.bg-gradient-purple {
        background-image : linear-gradient(8deg, #ff6ec4,  #7873f5)
}
.label-peach-white {
        background-image : linear-gradient(8deg, #FFD86F,  #FC6262);
        color : #fff;
}
.label-peach {
        background-image : linear-gradient(8deg, #FFD86F,  #FC6262);
        color : #000;
}
.bg-gradient-peach {
        background-image : linear-gradient(8deg, #FFD86F,  #FC6262)
}
.label-aqua-white {
        background-image : linear-gradient(8deg, #2096ff,  #05ffa3);
        color : #fff;
}
.label-aqua {
        background-image : linear-gradient(8deg, #2096ff,  #05ffa3);
        color : #000;
}
.bg-gradient-aqua {
        background-image : linear-gradient(8deg, #2096ff,  #05ffa3)
}
.label-blue-white {
        background-image : linear-gradient(8deg, #45cafc,  #303f9f);
        color : #fff;
}
.label-blue {
        background-image : linear-gradient(8deg, #45cafc,  #303f9f);
        color : #000;
}
.bg-gradient-blue {
        background-image : linear-gradient(8deg, #45cafc,  #303f9f)
}
.label-rgba-purple-white {
        background-image : linear-gradient(8deg, rgba(255,110,196,.9),  rgba(120115,245,.9));
        color : #fff;
}
.label-rgba-purple {
        background-image : linear-gradient(8deg, rgba(255,110,196,.9),  rgba(120115,245,.9));
        color : #000;
}
.bg-gradient-rgba-purple {
        background-image : linear-gradient(8deg, rgba(255,110,196,.9),  rgba(120115,245,.9))
}
.label-rgba-peach-white {
        background-image : linear-gradient(8deg, rgba(255,216,111,.9),  rgba(25298,98,.9));
        color : #fff;
}
.label-rgba-peach {
        background-image : linear-gradient(8deg, rgba(255,216,111,.9),  rgba(25298,98,.9));
        color : #000;
}
.bg-gradient-rgba-peach {
        background-image : linear-gradient(8deg, rgba(255,216,111,.9),  rgba(25298,98,.9))
}
.label-rgba-aqua-white {
        background-image : linear-gradient(8deg, rgba(32,150,255,.9),  rgba(5255,163,.9));
        color : #fff;
}
.label-rgba-aqua {
        background-image : linear-gradient(8deg, rgba(32,150,255,.9),  rgba(5255,163,.9));
        color : #000;
}
.bg-gradient-rgba-aqua {
        background-image : linear-gradient(8deg, rgba(32,150,255,.9),  rgba(5255,163,.9))
}
.label-rgba-blue-white {
        background-image : linear-gradient(8deg, rgba(69,202,252,.9),  rgba(4863,159,.9));
        color : #fff;
}
.label-rgba-blue {
        background-image : linear-gradient(8deg, rgba(69,202,252,.9),  rgba(4863,159,.9));
        color : #000;
}
.bg-gradient-rgba-blue {
        background-image : linear-gradient(8deg, rgba(69,202,252,.9),  rgba(4863,159,.9))
}
.text-note-primary, .text-note-primary > i {
        color : #176ac4;
}
.label-note-primary-white {
        background-color : #176ac4;
        color : #fff;
}
.label-note-primary {
        background-color : #176ac4;
        color : #000;
}
.bg-note-primary {
        background-color : #176ac4;
}
.text-note-secondary, .text-note-secondary > i {
        color : #58595a;
}
.label-note-secondary-white {
        background-color : #58595a;
        color : #fff;
}
.label-note-secondary {
        background-color : #58595a;
        color : #000;
}
.bg-note-secondary {
        background-color : #58595a;
}
.text-note-success, .text-note-success > i {
        color : #49a75f;
}
.label-note-success-white {
        background-color : #49a75f;
        color : #fff;
}
.label-note-success {
        background-color : #49a75f;
        color : #000;
}
.bg-note-success {
        background-color : #49a75f;
}
.text-note-danger, .text-note-danger > i {
        color : #e45460;
}
.label-note-danger-white {
        background-color : #e45460;
        color : #fff;
}
.label-note-danger {
        background-color : #e45460;
        color : #000;
}
.bg-note-danger {
        background-color : #e45460;
}
.text-note-warning, .text-note-warning > i {
        color : #c2a442;
}
.label-note-warning-white {
        background-color : #c2a442;
        color : #fff;
}
.label-note-warning {
        background-color : #c2a442;
        color : #000;
}
.bg-note-warning {
        background-color : #c2a442;
}
.text-note-info, .text-note-info > i {
        color : #2492a5;
}
.label-note-info-white {
        background-color : #2492a5;
        color : #fff;
}
.label-note-info {
        background-color : #2492a5;
        color : #000;
}
.bg-note-info {
        background-color : #2492a5;
}
.text-note-light, .text-note-light > i {
        color : #0f0f0f;
}
.label-note-light-white {
        background-color : #0f0f0f;
        color : #fff;
}
.label-note-light {
        background-color : #0f0f0f;
        color : #000;
}
.bg-note-light {
        background-color : #0f0f0f;
}
