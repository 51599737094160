#manager-error {
  div.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    i {
      font-size: 60px;
      margin: -40px 20px 20px 20px;
    }
  }
}
