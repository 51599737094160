/* COLOR */
$white: #FFFFFF;
$black: #000000;
$grey: #9899AC;
$blue: #3699FF;
$color-main: #1e1e2d;
$color-secondary: #1a1a27;

$color-primary: #009EF7;
$text-primary: #009EF7;

/* SIZE */
//$width_aside: 210px;
$width_aside: 265px;
$height_header: 62px;
$height_breadcrumb: 35px;
$height_subheader: 35px;
$height_c1: 62px;
$height_c2: 50px;
$height_c3: 43px;


/* FORM */
$field_height: 38px;
