.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000063;
  border-radius: inherit;
  z-index: 98;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}


manager-loader-wrapper,
manager-loader-wrapper-offcanvas,
manager-loader-wrapper-modal,
manager-loader-wrapper-dropdown,
manager-loader-wrapper-element {
  border-radius: inherit;
}
