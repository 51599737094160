$form_font-family: 'Roboto';
$form-font-size: 16px;
$form-field-height: 40px;
$form-placeholder-color: #9c9c9c;
$form-input-padding-left: 12px;
$border-radius: 6px;
$border-width: 1px;
$border-color: #E4E6EF;

/* GROUP */
.input-group, .btn-group {
  width: 100%;
  height: $form-field-height !important;
  font-family: $form_font-family;
  font-size: $form-font-size;

  .input-group-text {
    border: $border-width solid $border-color;
    border-radius: $border-radius 0 0 $border-radius;
    //padding: 10px !important;
    width: $form-field-height !important;
  }

  input, mat-form-field, .mat-mdc-text-field-wrapper, .mat-mdc-form-field-flex {
    width: 100%;
    height: $form-field-height !important;
  }

  .icon-calendar {
    position: relative;
    left: calc(100% - 10px);
    font-size: 18px;
    top: -30px;
  }

  /* LABEL */
  .mat-mdc-floating-label {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  mat-label {
    color: $form-placeholder-color;
  }

  /* INPUT */
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 50%;
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix {
    margin: 0;
    padding: 0;
  }

  .mdc-text-field--outlined .mdc-floating-label,
  .mdc-text-field--outlined .mdc-text-field__input{
    font-family: $form_font-family;
    font-size: $form-font-size;
  }

  .mat-mdc-notch-piece.mdc-notched-outline__leading {
    width: $form-input-padding-left !important;
    border-width: $border-width !important;
    border-color: $border-color !important;
    border-radius: 0 !important;
    border-right: 0 !important;
  }
  .mat-mdc-notch-piece.mdc-notched-outline__notch {
    border-width: $border-width !important;
    border-color: $border-color !important;
    border-radius: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .mat-mdc-notch-piece.mdc-notched-outline__trailing {
    border-width: $border-width !important;
    border-color: $border-color !important;
    border-radius: 0 $border-radius $border-radius 0 !important;
    border-left: 0 !important;
  }
  //// MAT DATEPICKER CONTENT Z-INDEX
  //.cdk-overlay-container{
  //  z-index:2000 !important;
  //}
}

/* SELECT */
ng-select {
  min-height: $form_field_height !important;
  border: 0;
  border-radius: 0;
  font-family: $form_font-family;
  font-size: $form-font-size;

  .ng-select-container.ng-appearance-outline {
    //height: $form_field_height + 3px;
    height: 100% !important;
    border: $border-width solid $border-color;
    border-radius: 0 $border-radius $border-radius 0;

    .ng-value-container {
      height: 100%;
      padding: 3px 0 0 $form-input-padding-left !important;

      .ng-placeholder {
        display: flex;
        align-items: center;
        height: $form-field-height !important;
        color: $form-placeholder-color;
        top: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
      }
      .ng-input {
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
      }
      .ng-value {
        //padding: 0 !important;
        margin: 0 !important;

        .ng-value-icon {
          padding: 5px 5px !important;
        }
        .ng-value-item {
          padding: 5px 10px !important;
        }
      }
    }
  }

  &.ng-select-filtered, &>.ng-has-value {
    //height: fit-content !important;

    .ng-placeholder {
      display: none !important;
    }
  }

  &.ng-select-single {
    .ng-input {
      position: relative;
    }
  }

  //.select-item {
  //  br {
  //    content: "";
  //    display: block;
  //    margin-bottom: -20px;
  //  }
  //}
}
.ng-dropdown-panel {
  z-index: 1055 !important;
  font-size: $form-font-size - 3px;
}

//// MAT FIELD
//.mat-form-field-appearance-outline {
//  .mat-form-field-wrapper {
//    padding-bottom: 0 !important;
//    margin: 0 !important;
//  }
//  .mat-form-field-outline-start {
//    border-radius: 0 !important;
//  }
//  .mat-form-field-outline {
//    color: #dae0e5 !important;
//    height: 40px;
//  }
//  .mat-form-field-infix {
//    padding: 0.7em 0 1em 0 !important; // POSITIONING THE CONTENT OF THE FIELD AT THE HORIZONTAL CENTER
//  }
//}
//
//.mat-form-field-label {  // DEFAULT CONFIGURATION OF THE LABEL
//  margin-top: -0.45em !important;
//  &:not(.mat-form-field-empty) {
//    margin-top: -0.2em !important;
//  }
//}
//
//.mat-form-field-appearance-outline.mat-focused.mat-accent {
//  .mat-form-field-outline-thick {
//    color: #1bc5bd !important; // BORDER COLOR WHEN THE FIELD IS FOCUSED
//  }
//}
//
//.mat-form-field.mat-focused {
//  .mat-form-field-label {
//    margin-top: -0.2em !important;
//    mat-label {
//      background-color: white;
//    }
//  }
//}
//
//.mat-form-field-infix {
//  top: -0.2em !important;
//  height: 40px;
//}
//
//.mat-form-field {
//  font-family: "Poppins";
//  width: 80%;
//}
//
//.api-background-grey{
//  .mat-form-field {
//    background-color: #f3f6f9;
//  }
//}
//
//.mat-form-field-empty.mat-form-field-label {
//  color: #828275;
//  font-weight: lighter;
//}
//
//.mat-form-field-can-float.mat-form-field-should-float {
//  .mat-form-field-label {
//    color: #828275;
//    font-weight: lighter;
//    font-family: 'Poppins';
//  }
//}
//
//.mat-form-field-appearance-outline .mat-form-field-prefix,
//.mat-form-field-appearance-outline .mat-form-field-suffix {
//  align-self: center !important;
//}
//
//// SELECT FIELDS
//.ng-select {
//  .ng-select-container.ng-appearance-outline {
//    min-height: 40px !important; // FIXED THE HEIGHT OF THE SELECT FIELD
//    &:after { // SET BORDER RADIUS AND BACKGROUND OF THE SELECT FIELD
//      height: calc(100% - .25em) !important;
//      border-bottom-left-radius: 0 !important;
//      border-top-left-radius: 0 !important;
//      background-color: #f3f6f9;
//    }
//    &:hover {
//      &:after {
//        border-color: #dae0e5 !important;
//      }
//    }
//    .ng-placeholder {
//      background-color: #f3f6f9;
//    }
//    .ng-value { // FOR VALUE BEING SHOWED UP
//      z-index: 1;
//    }
//  }
//
//  .ng-select-container.ng-appearance-outline.open {
//    &:after {
//      border-bottom-right-radius: 0 !important;
//    }
//  }
//
//  .ng-select-container { // PARAMETER FOR THE SELECT AND THE INPUT INSIDE IT
//    .ng-value-container {
//      padding: 1.5px 0 0 0 !important;
//      .ng-input {
//        >input {
//          padding: 5px !important;
//        }
//      }
//    }
//  }
//
//  .ng-has-value {
//    .ng-placeholder {
//      top: 1em !important;
//      background-color: #fff !important;
//    }
//  }
//}
//
//.ng-select.ng-select-focused {
//  .ng-select-container.ng-appearance-outline {
//    &:after {
//      border-width: 2px;
//      border-color: #1bc5bd !important;
//    }
//  }
//  .ng-select-container.ng-appearance-outline.active {
//    &:after {
//      border-color: #1bc5bd !important;
//    }
//  }
//  .ng-select-container {
//    .ng-value-container {
//      .ng-placeholder {
//        top: 1em !important;
//        background-color: #fff !important;
//        color: #4cd1cb;
//      }
//    }
//  }
//}
//
//.ng-select.ng-select-single {
//  .ng-select-container.ng-appearance-outline { // POSITION THE SELECT ARROW AND CLEAR BUTTON UP IN THE FIRST PLACE
//    .ng-arrow-wrapper {
//      bottom: 12px;
//      z-index: 1;
//    }
//    .ng-clear-wrapper {
//      bottom: 9px;
//      z-index: 1;
//    }
//  }
//  .ng-select-container {
//    .ng-value-container {
//      .ng-input {
//        z-index: 1;
//      }
//    }
//  }
//}
//
//.ng-select-container {
//  border: 0 !important;
//}
//
//.ng-dropdown-panel {
//  top: 3.1em !important;
//  border-top: 0 !important;
//  white-space: normal !important;
//  height: fit-content !important;
//}
//
//.ng-select.ng-select-opened {
//  .ng-placeholder {
//    top: 1em !important;
//  }
//}


/* WYSIWYG */
quill-editor {
  font-family: $form_font-family;
  font-size: $form-font-size;

  .ql-toolbar {
    border: $border-width solid $border-color !important;
    border-radius: $border-radius $border-radius 0 0 !important;
  }

  .ql-container {
    min-height: 200px !important;
    border: $border-width solid $border-color !important;
    border-top: 0 !important;
    border-radius: 0 0 $border-radius $border-radius !important;
  }
}

/* CUSTOM CLASS */
.api-background-grey {
  mat-form-field {
    background-color: #f3f6f9;
    color: #676767;
  }
}


//.api-list {
//  left: 15px;
//  right: 14px;
//}


//.select {
//  height: 40px;
//  width: 80%;
//}
//
//.input-icon {
//  height: 37px !important;
//  margin-top: 3.3px !important;
//}
//
//.pac-container{
//  position: absolute;
//  width: 71.6% !important;
//  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12);
//  background: #fff;
//  left:6.75em !important;
//  margin-top: 0 !important;
//  border-top: 0 !important;
//  overflow-scrolling: touch;
//
//  .input-option{
//    cursor: pointer;
//    line-height: 3em;
//    min-height: 3em;
//    white-space: nowrap;
//    overflow: hidden;
//    text-overflow: ellipsis;
//    padding: 0 16px;
//    text-decoration: none;
//    position: relative;
//    color: rgba(0,0,0,0.87);
//    text-align: left;
//    font-family: 'Poppins';
//    border: 0;
//
//    span{
//      text-transform: capitalize;
//    }
//  }
//}
//
//i.icon-calendar {
//  position: relative;
//  left: calc(100% - 20px);
//  bottom: 18px;
//  font-size: 18px;
//
//  &:hover {
//    color: #009EF7;
//  }
//}
//
//.valid-feedback {
//  //display: block;
//}
//.invalid-feedback {
//  display: block;
//}
//
//
//ngx-dropzone {
//  align-items: center;
//  justify-content: center;
//  flex-direction: column;
//  height: auto;
//  min-height: unset;
//
//  ngx-dropzone-label {
//    width: 100%;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    flex-direction: column;
//    margin: 0;
//    padding: 25px 10px;
//
//    ul {
//      list-style: none;
//      margin: 0;
//      padding: 0;
//      //font-size: 12px;
//      //font-style: italic;
//
//      li {
//        list-style: none;
//        display: inline-block;
//
//        &:after {
//          content: '-';
//          margin: 0 5px;
//        }
//
//        &:last-child:after {
//          content: none;
//          margin: 0;
//        }
//      }
//    }
//  }
//}

//.file-queue{
//  width: 50vw !important;
//  margin-top: 2%;
//}
//.my-drop-zone {
//  border: dotted 3px lightgray;
//  height: 15vh;
//}


// PHOTO EDITOR
//.editor-container{
//  position: relative;
//  height: 60vh !important;
//  //width: 50vw;
//  //margin-top: 2%;
//}
