.toast-container {
  z-index: 10;

  &.toast-top-right {
    z-index: 1001;
  }

  .toast-error, .toast-success, .toast-danger, .toast-warning {
    background-image: none;
    padding: 15px 15px 15px 0;
  }

  a.btn.btn-close {
    position: absolute;
    right: -10px;
    top: -3px;
    cursor: pointer;

    &:hover {
      i {
        color: #c1c0c0;
      }
    }

    i {
      color: $white;
      font-size: 16px;
    }
  }

  .ngx-toastr, .toast-full-width-content {
    display: block;

    .toastr-icon {
      i {
        color: $white;
        font-size: 25px;
        padding: 0 15px;
      }
    }
    .toastr-title {
      padding-bottom: 4px;
    }

    //&.login {
    //  @media not all and (min-resolution:.001dpcm) {
    //    @supports (-webkit-appearance:none) {
    //      //height: 72px;
    //    }
    //  }
    //}
    //&.form {
    //  @media not all and (min-resolution:.001dpcm) {
    //    @supports (-webkit-appearance:none) {
    //      //height: 56px;
    //    }
    //  }
    //}
  }
}
.toast-full-width-content {
  //position: absolute;
  position: fixed !important;
  top: $height_header + 5px;
  left: $width_aside + 10px;
  right: 10px;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}