.manager-detail-value {
  //display: flex;
  //flex-direction: row;
  //align-items: center;
}

.detail-value {
  span {
    display: inline;
  }
  div {
    display: inline;
  }
}

//.label.label-inline, .manager-detail-value{
.badge {
  font-weight: normal;
}
.badge.badge-inline{
  //height: auto;
  width: max-content;
}

ngb-progressbar {
  border: 1px solid #b5b5b570;
}
.detail-type-progress {
  i {
    width: fit-content;
    color: black;
    font-size: 12px;
  }
}
