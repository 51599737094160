@import "../var";

.wrapper-page {
  //margin-top: $height_header + $height_subheader;
  margin-top: $height_header;
  height: auto;
  min-height: calc(100vh - #{$height_header} - 28px);
  padding: 0;

  app-subheader {
    z-index: 2;
  }

  //#kt_grid {
  //  z-index: 1;
  //  margin-top: $height_breadcrumb + 15px;
  //  padding: 0;
  //
  //  &.with-subheader {
  //    margin-top: $height_subheader + $height_breadcrumb + 15px;
  //  }
  //}

  #kt_grid {
    z-index: 1;
    margin-top: 0;
    padding: 0;

    &.with-subheader {
      margin-top: 0px;
    }
  }
}