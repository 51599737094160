@import "../var";

#splash-screen {
  .logo {
    width: 33%;
  }

  .loading {
    width: 100px;
  }
}

.manager-loader-wrapper-page {
  position: fixed;
  top: $height_header;
  right: 0;
  bottom: 0;
  left: $width_aside;
  background: #00000063;
  z-index: 98;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  &.A1 {
    .spinner-border {
      border: 4px solid #007038;
      border-right-color: transparent;
    }
  }

  &.C1 {
    .spinner-border {
      border: 4px solid #ffd000;
      border-right-color: transparent;
    }
  }

  &.C2 {
    top: $height_c1 + $height_c2;

    .spinner-border {
      border: 4px solid #e30513;
      border-right-color: transparent;
    }
  }

  &.C3 {
    top: $height_c1 + $height_c2 + $height_c3;

    .spinner-border {
      border: 4px solid #f65d01;
      border-right-color: transparent;
    }
  }

  @media screen and (max-width: 992px) {
    left: 0;
  }
}

.manager-loader-wrapper-offcanvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000063;
  z-index: 98;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  &.A1 {
    .spinner-border {
      border: 4px solid #007038;
      border-right-color: transparent;
    }
  }

  &.C1 {
    top: 57px;

    .spinner-border {
      border: 4px solid #ffd000;
      border-right-color: transparent;
    }
  }

  &.C2 {
    top: 56px;
    &.HEADER {
      top: 126px;
    }

    .spinner-border {
      border: 4px solid #e30513;
      border-right-color: transparent;
    }
  }
}

.manager-loader-wrapper-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000063;
  z-index: 98;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  &.A1 {
    .spinner-border {
      border: 4px solid #007038;
      border-right-color: transparent;
    }
  }

  &.C1 {
    top: 57px;

    .spinner-border {
      border: 4px solid #ffd000;
      border-right-color: transparent;
    }
  }

  &.C2 {
    top: 56px;
    &.HEADER {
      top: 126px;
    }

    .spinner-border {
      border: 4px solid #e30513;
      border-right-color: transparent;
    }
  }
}

.manager-loader-wrapper-element {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.manager-loader-action {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000063;
  z-index: 98;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 100px;

  .spinner-border {
    border: 2px solid #dc08e5;
    border-right-color: transparent;
  }
}

.manager-loader-cache {
  position: absolute;
  right: 10px;
  padding-top: 16px;

  i {
    font-size: 25px;
    color: darkturquoise;
  }
}
