manager-header {
  width: 100%;
}

.header-dropdown {}

.header-modal {
  i {
    font-size: 25px;
    padding-right: 8px;
  }
}

.header-offcanvas {}

.header-page {
  position: fixed;
  right: 0;
  left: $width_aside;
  height: $height_header;
  //background-color: $color-secondary;
  background-color: $color-main;
  padding: 0 15px 0 0;
  z-index: 98;

  @media screen and (max-width: 992px) {
    $width_aside : 0;
    left: $width_aside;
    width: 100%;
  }
}

#menu-mobile {
  i {
    font-size: 18px;
  }
}