action-toolbar {
  z-index: 2;

  .dropdown-menu {
    position: absolute;
    z-index: 10;

    button, button:hover {
      border-radius: 0;

      &:first-child {
        border-start-start-radius: 10px;
        border-start-end-radius: 10px;
      }
      &:last-child {
        border-end-start-radius: 10px;
        border-end-end-radius: 10px;
      }
    }
  }

  .btn {
    height: 35px;
    padding: 0px 11px !important;
    color: #7E8299;
    background-color: #F5F8FA;

    i {

    }

    &:hover {
      color: #7E8299;
      background-color: #a4a4a4;

      i {
        color: #3a3a3a;
      }
    }
  }
}

button, .btn {
  // GREY
  &.btn-grey {
    color: #7E8299;
    background-color: #F5F8FA;

    i {
      color: #A1A5B7;
    }

    &:hover {
      color: #7E8299;
      background-color: #E4E6EF;

      i {
        color: #7E8299;
      }
    }
  }
  // GREEN
  &.btn-green {
    color: #50CD89;
    background-color: #e8FFF3;

    i {
      color: #50CD89;
    }

    &:hover {
      color: #FFFFFF;
      background-color: #50CD89;

      i {
        color: #FFFFFF;
      }
    }
  }
  // ORANGE
  &.btn-orange {
    color: #622600;
    background-color: #ffdba5;

    i {
      color: #622600;
    }

    &:hover {
      color: #FFFFFF;
      background-color: #ee5705;

      i {
        color: #FFFFFF;
      }
    }
  }
  // RED
  &.btn-red {
    color: #FFFFFF;
    background-color: #F1416C;

    i {
      color: #FFFFFF;
    }

    &:hover {
      color: #FFFFFF;
      background-color: #D9214E;

      i {
        color: #FFFFFF;
      }
    }
  }
  // BLUE
  &.btn-blue {
    color: #3368ef;
    background-color: #ade2ff;

    i {
      color: #6081fa;
    }

    &:hover {
      color: #FFFFFF;
      background-color: #0095E8;

      i {
        color: #FFFFFF;
      }
    }
  }

  // ONLY ICON
  &.only-icon {
    //background-color: #f6f6f6;
    //padding: 8px 8px !important;

    i {
      padding: 0;
      margin: 0;
    }
  }
}